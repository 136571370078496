import { action, Action } from 'typesafe-actions'

import { ContratoPessoalTypes } from './types'

export const getContratosGarantia = (payload: string): Action =>
  action(ContratoPessoalTypes.GET_CONTRATOS_GARANTIA, payload)

export const setContratosGarantia = (payload: unknown): Action =>
  action(ContratoPessoalTypes.SET_CONTRATOS_GARANTIA, payload)

export const setErrorContratoGarantia = (payload: string | boolean): Action =>
  action(ContratoPessoalTypes.SET_ERROR_CONTRATO_GARANTIA, payload)

export const setLoadingGarantia = (payload: boolean): Action =>
  action(ContratoPessoalTypes.SET_LOADING_GARANTIA, payload)

export const setSearchGarantia = (payload: Date): Action =>
  action(ContratoPessoalTypes.SEARCH_GARANTIA, payload)

export const getContratosAntecipacao = (payload: string): Action =>
  action(ContratoPessoalTypes.GET_CONTRATOS_ANTECIPACAO, payload)

export const setContratosAntecipacao = (payload: unknown): Action =>
  action(ContratoPessoalTypes.SET_CONTRATOS_ANTECIPACAO, payload)

export const setErrorContratosAntecipacao = (payload: string | boolean): Action =>
  action(ContratoPessoalTypes.SET_ERROR_CONTRATOS_ANTECIPACAO, payload)

export const setLoadingAntecipacao = (payload: boolean): Action =>
  action(ContratoPessoalTypes.SET_LOADING_ANTECIPACAO, payload)

export const getContratosResgateExpress = (payload: Date): Action =>
  action(ContratoPessoalTypes.GET_CONTRATOS_RESGATE_EXPRESS, payload)

export const setContratosResgateExpress = (payload: unknown): Action =>
  action(ContratoPessoalTypes.SET_CONTRATOS_RESGATE_EXPRESS, payload)

export const setErrorContratosResgateExpress = (payload: string | boolean): Action =>
  action(ContratoPessoalTypes.SET_ERROR_CONTRATOS_RESGATE_EXPRESS, payload)

export const setLoadingResgateExpress = (payload: boolean): Action =>
  action(ContratoPessoalTypes.SET_LOADING_RESGATE_EXPRESS, payload)

export const setSearchResgateExpress = (payload: Date): Action =>
  action(ContratoPessoalTypes.SEARCH_RESGATE_EXPRESS, payload)

export const clear = (): Action => action(ContratoPessoalTypes.CLEAR)
