import { BaseServices, HttpMethod } from '@services/base'

export default async (data: NewRelicDataRequest) => {
  const pathname = `${process.env.REACT_APP_NEW_RELIC_PATH}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST_CONSIGNADO

  const response = BaseServices.request({
    pathname,
    method,
    data,
    host,
  })
  return response
}

export interface NewRelicDataRequest {
  eventType: string
  step: string
  versaoWebView: string
}
