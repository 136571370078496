import { put } from 'typed-redux-saga'
import { isMobile } from 'react-device-detect'

import MobileBridge from '@interco/inter-mobile-bridge'
import services from '@services'
import { isOldBridge } from '@services/base/oldBridge'
import { BridgeService } from '@services/bridge'

import { setError, setLoadingAntecipacaoFGTS, setPropostaFGTS } from '../actions'
import { PropostaData, PropostaResponse } from '../types'

export default function* getPropostaFGTSSaga() {
  try {
    let userInfo = { cpf: '', account: '', conta: '' }
    yield put(setLoadingAntecipacaoFGTS(true))

    if (isOldBridge()) {
      userInfo = yield MobileBridge.getUserInfo()
    } else if (!BridgeService.isBrowser() && isMobile) {
      userInfo = yield BridgeService.getUserInfo()
    }

    const { data }: PropostaResponse = yield services.getPropostaFGTS({
      cpf: userInfo?.cpf || process?.env?.REACT_APP_MOCK_CPF_FGTS || '',
      conta: userInfo?.account || userInfo?.conta || process?.env?.REACT_APP_MOCK_CONTA_FGTS || '',
    })

    yield put(setPropostaFGTS(data))
  } catch (err) {
    yield put(setPropostaFGTS({} as PropostaData))
    yield put(setError())
  } finally {
    yield put(setLoadingAntecipacaoFGTS(false))
  }
}
