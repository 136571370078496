import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { PropostaRequestType } from '@services/getPropostasConsignado'
import { ApplicationState } from '@store/types'
import { clearStore } from '@store/actions'
import { ServiceResponseError } from '@exceptions'

import { setTodasPropostas, setErrorTodasPropostas, setLoadingTodasPropostas } from '../actions'

export default function* propostasConsignadoVisitante({ payload }: AnyAction) {
  yield put(setLoadingTodasPropostas(true))
  const { origem, isLogged } = yield select((state: ApplicationState) => state.ui.navigation)

  try {
    const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)

    const response: PropostaRequestType = yield services.getPropostasConsignadoVisitante(
      payload.cpf,
      accessToken,
      origem,
    )

    const propostas = response?.data?.propostas?.length > 0 ? response?.data?.propostas : []
    yield put(setTodasPropostas(propostas))
  } catch (error) {
    const ErroTyped = error as ServiceResponseError

    if (origem === 'site' && ErroTyped?.status === 401 && isLogged) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          onClose: () => clearStore(),
          actionCallback: () => clearStore(),
        }),
      )
    }
    yield put(setErrorTodasPropostas(true))
  }
}
