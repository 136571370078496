/**
 * Actions Types
 */

import { StatusContrato } from '@services/getContratosConsignadoVisitante'

export enum ContratoTypes {
  GET_CONTRATOS_CONSIGNADO = '@contratosConsignado/GET_CONTRATOS_CONSIGNADO',
  SET_CONTRATOS_CONSIGNADO = '@contratosConsignado/SET_CONTRATOS_CONSIGNADO',
  GET_CONTRATOS_CONSIGNADO_VISITANTE = '@contratosConsignado/GET_CONTRATOS_CONSIGNADO_VISITANTE',
  SET_LOADING_CONSIGNADO = '@contratosConsignado/SET_LOADING_CONSIGNADO',
  SET_ERROR_CONSIGNADO = '@contratosConsignado/SET_ERROR_CONSIGNADO',
  SEARCH_CONSIGNADO = '@contratosConsignado/SEARCH_CONSIGNADO',
  CLEAR = '@contratosConsignado/CLEAR',
  SET_CONTRATO_SELECIONADO = '@contratosConsignado/SET_CONTRATO_SELECIONADO',
}

/**
 * State Type
 */

export interface ContratoState {
  readonly contratosConsignado?: BuildContratoType[]
  readonly errorConsignado: boolean | string
  readonly loadingContratosConsignado: boolean
  readonly searchConsignado: Date
  readonly contratoSelecionado?: string
}

export interface PayloadGetContratosType {
  cpf: string
}

export interface ContratosReturnType {
  contratos: BuildContratoType[]
}

export interface ContratoArray {
  numeroContrato: string
  valorContrato: number
  proximoVencimento: string
  codigoProduto: string
}

export interface BuildContratoType {
  numero: string
  codigo: string
  valorContrato: number
  proximoVencimento: string
  codigoProduto: string
  dataContrato?: Date
  status?: StatusContrato
  modalidade?: string
}
