import { Reducer } from 'redux'

import { LoginState, LoginTypes } from './types'

const INITIAL_STATE: LoginState = {
  guid: '',
  accessToken: null,
  notificationTypeEmail: false,
  gerarGuidSuccess: false,
  solicitaTokenSuccess: false,
  loading: false,
  gerarGuidLoading: false,
  sessaoExpirada: false,
}

const reducer: Reducer<LoginState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoginTypes.SET_GUID:
      return { ...state, guid: action.payload }
    case LoginTypes.SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload }
    case LoginTypes.SET_NOTIFICATION_TYPE_EMAIL:
      return { ...state, notificationTypeEmail: action.payload }
    case LoginTypes.GERAR_GUID_REQUEST:
      return { ...state, gerarGuidLoading: true }
    case LoginTypes.SET_GERAR_GUID_SUCCESS:
      return { ...state, gerarGuidSuccess: true, gerarGuidLoading: false }
    case LoginTypes.SET_GERAR_GUID_LOADING:
      return { ...state, gerarGuidLoading: false }
    case LoginTypes.SESSAO_EXPIRADA:
      return { ...state, sessaoExpirada: action.payload }

    case LoginTypes.SOLICITA_TOKEN_REQUEST:
      return { ...state }
    case LoginTypes.SOLICITA_TOKEN_SUCCESS:
      return { ...state, solicitaTokenSuccess: true, loading: false }

    case LoginTypes.EFETUAR_LOGIN_REQUEST:
      return { ...state, loading: true }
    case LoginTypes.EFETUAR_LOGIN_SUCCESS:
      return { ...state, loading: false }
    case LoginTypes.SET_EFETUAR_LOGIN_LOADING:
      return { ...state, loading: false }

    case LoginTypes.CLEAR_LOGIN:
      return INITIAL_STATE

    default:
      return state
  }
}

export default reducer
