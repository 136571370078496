import { BaseServices, HttpMethod } from '@services/base'

export default (data: EfetuarLoginRequest) => {
  const captcha = data.recaptchaToken
  const pathname = process.env.REACT_APP_EFETUAR_LOGIN
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers = { 'token-captcha': captcha }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

export interface EfetuarLoginRequest {
  token: string
  guid: string
  recaptchaToken: string
}
