import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { EfetuarLoginRequest } from '@services/postEfetuarLogin'
import { showError } from '@store/ui/error/actions'
import { efetuarLoginSuccess, setEfetuarLoginLoading } from '@store/login/actions'
import services from '@services'

import { EfetuarLoginPayload } from '../types'

export default function* postEfetuarLoginSaga(action: AnyAction) {
  const payload = action.payload as EfetuarLoginPayload

  try {
    const efetuarLogin: EfetuarLoginRequest = {
      token: payload.token,
      guid: payload.guid,
      recaptchaToken: payload.recaptchaToken,
    }
    yield services.postEfetuarLogin(efetuarLogin)
    yield put(efetuarLoginSuccess(true))
    payload.onSuccess()
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao efetuar login',
        message: 'Aconteceu um erro ao efetuar login!',
        actionTitle: 'Tentar Novamente',
        onClose: yield put(setEfetuarLoginLoading(false)),
        actionCallback: () => setEfetuarLoginLoading(false),
      }),
    )
  }
}
