import React, { memo } from 'react'

import { InterUILoading } from '@interco/inter-ui-react-lib'
import { Colors, P } from '@interco/cp-react-ui-lib'

import { Grid } from './styles'

interface Props {
  height?: number | string
  margin?: string
  size?: 'md' | 'sm' | 'ld' | 'xl' | 'xs' | undefined
  thickness?: number
  loadingMessage?: string
}

const InnerLoading = ({ height, margin, size, thickness, loadingMessage }: Props) => (
  <Grid margin={margin} height={height}>
    <InterUILoading size={size} strokeWidth={thickness} />
    {loadingMessage && (
      <P
        fontSize="14px"
        fontWeight="600"
        variant="sora"
        lineHeight="17px"
        color={Colors.GRAY500}
        textAlign="left"
        margin="24px 0 0"
      >
        {loadingMessage}
      </P>
    )}
  </Grid>
)

InnerLoading.defaultProps = {
  height: '100%',
  margin: '0',
  size: 'ld',
  thickness: 4,
  loadingMessage: undefined,
}

export default memo(InnerLoading)
