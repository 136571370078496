import { select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { NewRelicDataRequest } from '@services/postTrackingNewRelic'
import { ApplicationState } from '@store/types'

import packageJson from '../../../../package.json'

export default function* postTrackingNewRelicSaga({ payload }: AnyAction) {
  try {
    const { isApp } = yield select((state: ApplicationState) => state.ui.navigation)

    const newRelicData: NewRelicDataRequest = {
      eventType: 'AREA_CLIENTE_CONTRATO_CONSIGNADO',
      step: (isApp ? '' : 'PORTAL_') + payload.step,
      versaoWebView: packageJson?.version,
    }
    yield services.postTrackingNewRelic(newRelicData)
  } catch (error: unknown) {
    // avaliar tratamento de erro para o tracking posteriormente
  }
}
