/**
 * Actions Types
 */

export enum TabTypes {
  SET_SELECTED_TAB = '@tabs/SET_SELECTED_TAB',
  SET_SELECTED_TAB_DETALHAMENTO = '@tabs/SET_SELECTED_TAB_DETALHAMENTO',
  TAB_NOT_CHANGED = '@tabs/TAB_NOT_CHANGED',
}

/**
 * State Type
 */

export interface TabState {
  readonly selectedTab: string
  readonly selectedTabDetalhamento: string
  readonly tabChanged: boolean
}
