import { put } from 'typed-redux-saga'

import services from '@services'
import { AntecipacaoFgtsType } from '@services/getAntecipacaoFgts'

import { setLoadingAntecipacaoFGTS, setShowAntecipacaoFGTS } from '../actions'

export default function* getAntecipacaoFGTS() {
  try {
    yield put(setLoadingAntecipacaoFGTS(true))
    const conta = process.env.REACT_APP_MOCK_CONTA_FGTS || ''
    const response: AntecipacaoFgtsType = yield services.getAntecipacaoFgts(conta)
    if (response?.data?.status === 200 || response?.status === 200) {
      yield put(setShowAntecipacaoFGTS(true))
    } else {
      yield put(setShowAntecipacaoFGTS(false))
    }
  } catch (err) {
    yield put(setShowAntecipacaoFGTS(false))
  } finally {
    yield put(setLoadingAntecipacaoFGTS(false))
  }
}
