import { BaseServices, HttpMethod } from '@services/base'

export default (conta: string) => {
  const pathname = `${process.env.REACT_APP_GET_ANTECIPACAO_FGTS}`
  const method = HttpMethod.GET
  const headers = { 'x-inter-conta': conta }
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const response = BaseServices.request<AntecipacaoFgtsType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface AntecipacaoFgtsType {
  status: number
  data: {
    status: number
  }
}
