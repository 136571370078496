import React from 'react'

import { useSelector } from 'react-redux'

import { InterUISeparator } from '@interco/inter-ui-react-lib'
import { P } from '@interco/cp-react-ui-lib'
import { ApplicationState } from '@store/types'
import { useAuth } from '@hooks'

import { Button, GridCpf, GridHeader, SpanCpf } from './styles'

const HeaderLogoffWeb = () => {
  const { loading } = useSelector((state: ApplicationState) => state.cpf)
  const { isApp, isLogged } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { cpf } = useSelector((state: ApplicationState) => state.cpf)
  const { logout } = useAuth()

  return !isApp && isLogged && !loading ? (
    <>
      <GridHeader>
        <GridCpf>
          <P margin="0" fontSize="12px" lineHeight="15px">
            {'CPF '}
            <SpanCpf>{cpf}</SpanCpf>
          </P>
        </GridCpf>
        <Button
          variant="secondary"
          width="70px"
          onClick={() => {
            logout()
          }}
        >
          Sair
        </Button>
      </GridHeader>
      <InterUISeparator height="2px" />
    </>
  ) : (
    <></>
  )
}

export default HeaderLogoffWeb
