import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import getClienteSaga from './sagas/getClienteSaga'
import getCpfSaga from './sagas/getCpfSaga'
import { CpfTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(CpfTypes.GET_CPF_SAGA, getCpfSaga)
  yield* takeLatest(CpfTypes.GET_CLIENTE_SAGA, getClienteSaga)
}
