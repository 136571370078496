import styled from 'styled-components'

export const ContentPage = styled.div<{
  hasHeader: boolean
}>`
  ${({ hasHeader }) =>
    hasHeader &&
    ` padding-top: 56px;
      @media (min-width: 991px){
        padding-top: 70px;
      }  
  `};
`
export const ContainerFooter = styled.div<{
  isUnroll: boolean
}>`
  ${({ isUnroll }) =>
    isUnroll &&
    `
    div[id = 'footer'] {
      div + div + div + div {
       div {
          ul + ul {
           li {
             display: none;
           }
           li + li {
             display: block;
           }
          }
       }
      }
    }
  `};
`
