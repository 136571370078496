import { Reducer } from 'redux'

import { AntecipacaoVeicularTypes, AntecipacaoVeicularState } from './types'

const INITIAL_STATE: AntecipacaoVeicularState = {
  loadingAntecipacaoVeicula: false,
  showAntecipacaoVeicular: false,
}

const reducer: Reducer<AntecipacaoVeicularState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AntecipacaoVeicularTypes.SET_LOADING_ANTECIPACAO_VEICULAR:
      return {
        ...state,
        loadingAntecipacaoVeicula: action.payload,
      }
    case AntecipacaoVeicularTypes.SET_SHOW_ANTECIPACAO_VEICULAR:
      return { ...state, showAntecipacaoVeicular: action.payload }

    default:
      return state
  }
}

export default reducer
