import { SolicitaTokenPayload } from '@store/login/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (payload: SolicitaTokenPayload) => {
  const captcha = payload.recaptchaToken
  const pathname = process.env.REACT_APP_SOLICITA_TOKEN
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const headers = {
    'token-captcha': captcha,
    guid: payload.guid,
    notificationType: payload.tipo || '',
  }

  const response = BaseServices.request<SolicitaTokenPayload>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}
