import { Reducer } from 'redux'

import { CpfState, CpfTypes } from './types'

const INITIAL_STATE: CpfState = {
  cpf: '',
  redirectToUnroll: false,
  isDoneCpf: false,
  loading: false,
}

const reducer: Reducer<CpfState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CpfTypes.SET_CPF:
      return {
        ...state,
        cpf: action.payload,
      }
    case CpfTypes.SET_REDIRECT_TO_UNROLL:
      return {
        ...state,
        redirectToUnroll: action.payload,
      }
    case CpfTypes.SET_DONE_CPF:
      return { ...state, isDoneCpf: action.payload }
    case CpfTypes.SET_LOADING_CPF:
      return { ...state, loading: action.payload }
    case CpfTypes.CLEAR:
      return { ...INITIAL_STATE }

    default:
      return state
  }
}

export default reducer
