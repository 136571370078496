import React from 'react'

import { Colors, P } from '@interco/cp-react-ui-lib'

import { Row, RowContratos, TableContainer } from './styles'

interface Props {
  children?: JSX.Element[] | JSX.Element
  tableHeaders: string[]
  contratos?: boolean
}

export type StatusType = 'PENDENTE' | 'PROCESSANDO' | 'CONCLUIDO'

const Table = ({ children, tableHeaders, contratos }: Props) => {
  const styleHeader = () =>
    tableHeaders?.map((header: string) => (
      <P fontSize="12px" lineHeight="15px" color={Colors.GRAY500} textAlign="left" key={header}>
        {header}
      </P>
    ))

  return (
    <TableContainer>
      {contratos ? <RowContratos>{styleHeader()}</RowContratos> : <Row>{styleHeader()}</Row>}
      {children}
    </TableContainer>
  )
}
export default Table
