import { put, select } from 'typed-redux-saga'

import services from '@services'
import { AntecipacaoType } from '@services/postTaxas'
import { ApplicationState } from '@store/types'

import { setLoadingAntecipacao, setShowAntecipacao } from '../actions'

export default function* postTaxas() {
  const { cpf } = yield select((state: ApplicationState) => state.cpf)
  try {
    yield put(setLoadingAntecipacao(true))
    const response: AntecipacaoType = yield services.postTaxas(cpf)
    yield put(setShowAntecipacao(response?.data?.vl_total_operacao > 0))
  } catch (err) {
    yield put(setShowAntecipacao(false))
    yield put(setLoadingAntecipacao(false))
  } finally {
    yield put(setLoadingAntecipacao(false))
  }
}
