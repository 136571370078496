import { action, Action } from 'typesafe-actions'

import { ContratoTypes } from './types'

export const getContratosConsignado = (payload: string): Action =>
  action(ContratoTypes.GET_CONTRATOS_CONSIGNADO, payload)

export const getContratosConsignadoVisitante = (): Action =>
  action(ContratoTypes.GET_CONTRATOS_CONSIGNADO_VISITANTE)

export const setContratosConsignado = (payload: unknown): Action =>
  action(ContratoTypes.SET_CONTRATOS_CONSIGNADO, payload)

export const setErrorConsignado = (payload: boolean | string): Action =>
  action(ContratoTypes.SET_ERROR_CONSIGNADO, payload)

export const setLoadingConsignado = (payload: boolean): Action =>
  action(ContratoTypes.SET_LOADING_CONSIGNADO, payload)

export const setContratoSelecionado = (payload: string): Action =>
  action(ContratoTypes.SET_CONTRATO_SELECIONADO, payload)

export const setSearchConsignado = (payload: Date): Action =>
  action(ContratoTypes.SEARCH_CONSIGNADO, payload)

export const clear = (): Action => action(ContratoTypes.CLEAR)
