import { action, Action } from 'typesafe-actions'

import { ContratoImobiliarioTypes } from './types'

export const getContratosImobiliario = (): Action =>
  action(ContratoImobiliarioTypes.GET_CONTRATOS_IMOBILIARIO, {})

export const setContratosImobiliario = (payload: unknown): Action =>
  action(ContratoImobiliarioTypes.SET_CONTRATOS_IMOBILIARIO, payload)

export const setErrorImobiliario = (payload: boolean | string): Action =>
  action(ContratoImobiliarioTypes.SET_ERROR_IMOBILIARIO, payload)

export const setLoadingImobiliario = (payload: boolean): Action =>
  action(ContratoImobiliarioTypes.SET_LOADING_IMOBILIARIO, payload)

export const setSearchImobiliario = (payload: Date): Action =>
  action(ContratoImobiliarioTypes.SEARCH_IMOBILIARIO, payload)

export const clear = (): Action => action(ContratoImobiliarioTypes.CLEAR)
