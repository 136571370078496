enum routes {
  ROOT = '/',
  HOME = '/emprestimo',
  LOGIN = '/login',
  EMPRESTIMO_CONSIGNADO = '/consignado',
  EMPRESTIMO_PESSOAL = '/pessoal',
  SAQUE_ANIVERSARIO = '/saque-aniversario',
  EMPRESTIMO_IMOBILIARIO = '/imobiliario',
  DESENROLA_BRASIL = '/portal-boletos',
}

export default routes
