import { put } from 'typed-redux-saga'

import services from '@services'
import { CheckElegibilidadeResponse } from '@services/getElegivel'

import { setShowAntecipacaoVeicular, setLoadingAntecipacaoVeicular } from '../actions'

export default function* getAntecipacaoVeicular() {
  try {
    yield put(setLoadingAntecipacaoVeicular(true))
    const response: CheckElegibilidadeResponse = yield services.getElegivel()
    if (response?.data.clienteElegivel) {
      yield put(setShowAntecipacaoVeicular(true))
    } else {
      yield put(setShowAntecipacaoVeicular(false))
    }
  } catch (err) {
    yield put(setShowAntecipacaoVeicular(false))
  } finally {
    yield put(setLoadingAntecipacaoVeicular(false))
  }
}
