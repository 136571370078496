import styled from 'styled-components'

import { InterUITag } from '@interco/inter-ui-react-lib'

export const TableContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

export const Row = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 0.8fr 1fr 1fr 0.5fr 0.1fr;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  text-align: center;
`

export const RowContratos = styled.div`
  display: grid;
  grid-template-columns: 1.3fr 0.75fr 0.75fr 0.55fr;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 32px;
  text-align: center;
`

export const InterUITagCustom = styled(InterUITag)`
  max-width: 85px;
`
