import { combineReducers } from 'redux'

import contratos from './contratos'
import { ContratoPessoalState } from './contratos/types'

export interface PessoalState {
  contratos: ContratoPessoalState
}

export default combineReducers({
  contratos,
})
