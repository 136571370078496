import { Redirect, Route, RouteProps } from 'react-router-dom'

import { useAuth } from '@hooks'

import routes from './routes'

const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const { accessToken } = useAuth()
  return (
    <Route
      {...rest}
      render={({ location }) =>
        accessToken ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.LOGIN,
              state: { from: location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
