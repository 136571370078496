import { isChrome, isIOS } from 'react-device-detect'

import InterWebviewBridge, {
  interWbHttp,
  interWbNavigate,
  interWbSession,
  IWbHttpData,
  IWbUserInfo,
  WbEnvironments,
  WbSessionActions,
} from '@interco/inter-webview-bridge'
import { HttpMethod, TResponseType } from '@services/base/types'

import { TWbAppInfo, TParamsRequestBrigde, TOpenPdfViewerAction } from './types'

export * from './types'

export class BridgeService {
  private static interWb = InterWebviewBridge.getInstance()

  static getEnvironment(): WbEnvironments {
    return this.interWb.getEnvironment()
  }

  static isBrowser(): boolean {
    return this.interWb.getEnvironment() === WbEnvironments.BROWSER || (isChrome && isIOS)
  }

  static getUserInfo(): Promise<IWbUserInfo> {
    return interWbSession.getUserInfo()
  }

  static getAppInfo(): Promise<TWbAppInfo> {
    return interWbSession.getAppInfo()
  }

  static async backToNative(): Promise<void> {
    await interWbNavigate.requestGoBack()
  }

  static async openDeepLink(url: string): Promise<void> {
    await interWbNavigate.openDeepLink(url)
  }

  static async addBackListener(listenerMobileBack: () => void): Promise<void> {
    await this.interWb.addWebviewEventListener(WbSessionActions.GO_BACK, listenerMobileBack)
  }

  static async removeBackListener(listenerMobileBack: () => void): Promise<void> {
    this.interWb.removeWebviewEventListener(WbSessionActions.GO_BACK, listenerMobileBack)
  }

  static async request<T>({
    headers = { 'Content-Type': 'application/json' },
    pathname,
    method,
    data,
  }: TParamsRequestBrigde): Promise<TResponseType<T>> {
    const endpoint = `/mobile/${pathname}`
    let bridgeResponse = {} as IWbHttpData

    switch (method) {
      case HttpMethod.GET: {
        bridgeResponse = await interWbHttp.get(endpoint, headers)
        break
      }
      case HttpMethod.POST: {
        bridgeResponse = await interWbHttp.post(endpoint, data as Record<string, unknown>, headers)
        break
      }
      case HttpMethod.PUT: {
        bridgeResponse = await interWbHttp.put(endpoint, data as Record<string, unknown>, headers)
        break
      }
      case HttpMethod.DELETE: {
        bridgeResponse = await interWbHttp.delete(endpoint, headers)
        break
      }
      default:
        break
    }

    let parsedData = {} as T
    if (typeof bridgeResponse.response === 'string') {
      parsedData = JSON.parse(bridgeResponse.response || '{}')
    }

    return {
      data: parsedData,
      status: bridgeResponse.httpStatus,
      headers: bridgeResponse.headers,
    }
  }

  static openPdfViewerAction({ url, title }: TOpenPdfViewerAction) {
    if (BridgeService.isBrowser()) {
      window.open(url)
    } else {
      this.interWb.execute({
        action: 'openPdfViewer',
        metadata: { url, title },
      })
    }
  }
}
