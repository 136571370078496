import { action, Action } from 'typesafe-actions'

import { LoginTypes, GerarGuidPayload, SolicitaTokenPayload, EfetuarLoginPayload } from './types'

export const setGerarGuidSuccess = (payload?: boolean): Action =>
  action(LoginTypes.SET_GERAR_GUID_SUCCESS, payload)
export const setGuid = (payload?: string): Action => action(LoginTypes.SET_GUID, payload)
export const setAccessToken = (payload: string | null): Action =>
  action(LoginTypes.SET_ACCESS_TOKEN, payload)
export const setNotificationTypeEmail = (payload?: boolean): Action =>
  action(LoginTypes.SET_NOTIFICATION_TYPE_EMAIL, payload)
export const gerarGuidRequest = (payload?: GerarGuidPayload): Action =>
  action(LoginTypes.GERAR_GUID_REQUEST, payload)
export const setGerarGuidLoading = (payload: boolean): Action =>
  action(LoginTypes.SET_GERAR_GUID_LOADING, payload)

export const solicitaTokenSuccess = (payload: boolean): Action =>
  action(LoginTypes.SOLICITA_TOKEN_SUCCESS, payload)
export const solicitaTokenRequest = (payload: SolicitaTokenPayload): Action =>
  action(LoginTypes.SOLICITA_TOKEN_REQUEST, payload)

export const efetuarLoginRequest = (payload?: EfetuarLoginPayload): Action =>
  action(LoginTypes.EFETUAR_LOGIN_REQUEST, payload)
export const efetuarLoginSuccess = (payload: boolean): Action =>
  action(LoginTypes.EFETUAR_LOGIN_SUCCESS, payload)
export const setEfetuarLoginLoading = (payload: boolean): Action =>
  action(LoginTypes.SET_EFETUAR_LOGIN_LOADING, payload)

export const clearLogin = (): Action => action(LoginTypes.CLEAR_LOGIN)
export const setSessaoExpirada = (payload: boolean): Action =>
  action(LoginTypes.SESSAO_EXPIRADA, payload)
