import { action, Action } from 'typesafe-actions'

import { NavigationTypes } from './types'

export const setIsApp = (payload: boolean): Action => action(NavigationTypes.SET_IS_APP, payload)
export const setOrigem = (payload: string): Action => action(NavigationTypes.SET_ORIGEM, payload)
export const setTitle = (payload: string): Action => action(NavigationTypes.SET_TITLE, payload)
export const setHideHeader = (payload?: boolean): Action =>
  action(NavigationTypes.SET_HIDE_HEADER, payload === undefined ? true : payload)
export const setHideFooter = (payload?: boolean): Action =>
  action(NavigationTypes.SET_HIDE_FOOTER, payload === undefined ? true : payload)
export const setIsLogged = (payload: boolean): Action =>
  action(NavigationTypes.SET_IS_LOGGED, payload)
export const setPageAction = (payload: string): Action =>
  action(NavigationTypes.SET_PAGE_ACTION, payload)
