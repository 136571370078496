import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import moment from 'moment'

import services from '@services'
import { BuildContratoType } from '@store/consignado/contratos/types'

import { setLoadingContratosFGTS, setErroContratoFGTS, setContratos } from '../actions'
import { ContratoResponse } from '../types'

export default function* getAntecipacaoFGTS({ payload }: AnyAction) {
  try {
    yield put(setLoadingContratosFGTS(true))
    const cpf = payload || process.env.REACT_APP_MOCK_CPF_FGTS || ''
    const response: ContratoResponse = yield services.getContratosFGTS(cpf)
    const buildContratos: BuildContratoType[] = []

    yield response?.data?.contratos.forEach((contrato) => {
      const buildContrato = {
        numero: contrato?.uuidContrato || '',
        codigo: contrato?.uuidContrato || '',
        valorContrato: Number(contrato?.valorContrato),
        proximoVencimento: moment(contrato?.dataProximoVencimento, 'YYYY-MM-DD').format(
          'DD/MM/YYYY',
        ),

        codigoProduto: contrato?.uuidContrato,
      }
      buildContratos.push(buildContrato)
    })

    yield put(setContratos(buildContratos))
  } catch (e) {
    setErroContratoFGTS()
  } finally {
    yield put(setLoadingContratosFGTS(false))
  }
}
