import { Reducer } from 'redux'

import { AntecipacaoState, AntecipacaoTypes } from './types'

const INITIAL_STATE: AntecipacaoState = {
  showAntecipacao: false,
  loadingAntecipacao: false,
  searchedDate: null,
}

const reducer: Reducer<AntecipacaoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AntecipacaoTypes.SET_SHOW_ANTECIPACAO:
      return {
        ...state,
        showAntecipacao: action.payload,
      }
    case AntecipacaoTypes.SET_LOADING_ANTECIPACAO:
      return { ...state, loadingAntecipacao: action.payload }
    case AntecipacaoTypes.SET_SEARCHED_DATE:
      return { ...state, searchedDate: action.payload }
    default:
      return state
  }
}

export default reducer
