import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import getPropostaConsignadoSaga from './sagas/getPropostaConsignadoSaga'
import propostasConsignadoVisitante from './sagas/getPropostaConsignadoVisitanteSaga'
import { PropostaTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(PropostaTypes.GET_PROPOSTAS_CONSIGNADO_NOVO_SAGA, getPropostaConsignadoSaga)
  yield* takeLatest(PropostaTypes.GET_TODAS_PROPOSTAS, propostasConsignadoVisitante)
}
