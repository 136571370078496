import { UiState } from './ui'
import { ConsignadoState } from './consignado'
import { CpfState } from './cpf/types'
import { AntecipacaoFgtsState } from './antecipacaoFgts/types'
import { AntecipacaoState } from './antecipacaoSalario/types'
import { PessoalState } from './pessoal'
import { ImobiliarioState } from './imobiliario'
import { AntecipacaoVeicularState } from './antecipacaoVeicular/types'

export interface ApplicationState {
  ui: UiState
  consignado: ConsignadoState
  pessoal: PessoalState
  cpf: CpfState
  antecipacaoFgts: AntecipacaoFgtsState
  antecipacaoSalario: AntecipacaoState
  imobiliario: ImobiliarioState
  antecipacaoVeicular: AntecipacaoVeicularState
}

export enum RootTypes {
  CLEAR = '@root/CLEAR',
}
