import React, { useEffect, useState } from 'react'

import { useSelector, useDispatch } from 'react-redux'

import { InterUIButton } from '@interco/inter-ui-react-lib'
import { P, Dialog } from '@interco/cp-react-ui-lib'
import { clearError } from '@store/ui/error/actions'
import { ApplicationState } from '@store/types'

const ErrorMessage = () => {
  const dispatch = useDispatch()

  const {
    showError = false,
    message = 'Ocorreu um erro inesperado, tente novamente.',
    title = 'Ocorreu um erro',
    code,
    actionTitle = 'Entendi',
    actionCallback,
    block,
    onClose,
  } = useSelector((state: ApplicationState) => state.ui.error)

  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  const [open, setOpen] = useState(showError)

  useEffect(() => {
    dispatch(clearError())
  }, [dispatch])

  useEffect(() => {
    setOpen(showError)
  }, [showError, setOpen])

  return (
    <Dialog
      onHide={
        !block
          ? () => {
              setOpen(false)
              if (onClose instanceof Function) {
                dispatch(onClose())
              }
              setTimeout(() => {
                dispatch(clearError())
              }, 600)
            }
          : () => null
      }
      title={title}
      toggle={open}
      mobile={isApp}
    >
      <P margin={code ? 'unset' : '0 0 15px 0;'}>{message}</P>
      {code && <P margin="0 0 15px 0;">{`COD (${code})`}</P>}

      <InterUIButton
        onClick={() => {
          setOpen(false)
          setTimeout(() => {
            dispatch(clearError())
            if (actionCallback instanceof Function) {
              dispatch(actionCallback())
            }
          }, 600)
        }}
      >
        {actionTitle}
      </InterUIButton>
    </Dialog>
  )
}

export default ErrorMessage
