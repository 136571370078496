import React from 'react'

import { useSelector } from 'react-redux'

import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import { ApplicationState } from '@store/types'

import { ContainerFooter } from './styles'

const HeaderAndFooter: React.FC = ({ children }) => {
  const { isApp, hideFooter } = useSelector((state: ApplicationState) => state.ui.navigation)
  const { redirectToUnroll } = useSelector((state: ApplicationState) => state.cpf)

  const showFooter = () => !isApp && !hideFooter

  return (
    <>
      {children}
      {showFooter() && (
        <ContainerFooter
          isUnroll={
            window.location.search === '?desenrola-brasil' ||
            window.location.pathname === '/portal-boletos' ||
            redirectToUnroll
          }
        >
          <Footer />
        </ContainerFooter>
      )}
    </>
  )
}

export default HeaderAndFooter
