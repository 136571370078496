import styled from 'styled-components'

import { FontFamily, Grid } from '@interco/cp-react-ui-lib'

export const Input = styled.input`
  font-family: ${FontFamily.Sora};
  background: transparent;
  border: none;
  box-sizing: border-box;
  border-radius: 4;
  color: #161616;
  font-size: 2em;
  display: flex;
  width: 100%;
  text-align: center;
  padding: 0.8rem 0;
  ::placeholder {
    color: #b6b7bb;
    font-weight: normal;
  }
`

export const GridPrincipal = styled(Grid)`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const GridSecundario = styled(Grid)`
  border-bottom: 2px solid #ff7a00;
  margin: 8px;
`
