import { action, Action } from 'typesafe-actions'

import { BuildContratoType } from '@store/consignado/contratos/types'

import { AntecipacaoFgtsTypes, PropostaData } from './types'

export const setShowAntecipacaoFGTS = (payload: boolean): Action =>
  action(AntecipacaoFgtsTypes.SET_SHOW_ANTECIPACAO_FGTS, payload)
export const setLoadingAntecipacaoFGTS = (payload: boolean): Action =>
  action(AntecipacaoFgtsTypes.SET_LOADING_ANTECIPACAO_FGTS, payload)
export const getAntecipacaoFGTS = (): Action => action(AntecipacaoFgtsTypes.GET_ANTECIPACAO_FGTS)
export const getPropostaFGTS = (): Action => action(AntecipacaoFgtsTypes.GET_PROPOSTA_FGTS)
export const setPropostaFGTS = (payload: PropostaData): Action =>
  action(AntecipacaoFgtsTypes.SET_PROPOSTA_FGTS, payload)
export const setError = (): Action => action(AntecipacaoFgtsTypes.SET_ERROR)

export const setLoadingContratosFGTS = (payload: boolean): Action =>
  action(AntecipacaoFgtsTypes.SET_LOADING_ANTECIPACAO_FGTS, payload)
export const setContratos = (payload: BuildContratoType[]): Action =>
  action(AntecipacaoFgtsTypes.SET_CONTRATOS_FGTS, payload)

export const getContratosFGTS = (cpf: string): Action =>
  action(AntecipacaoFgtsTypes.GET_CONTRATOS_FGTS, cpf)
export const setErroContratoFGTS = (): Action => action(AntecipacaoFgtsTypes.SET_CONTRATO_ERRO_FGTS)
