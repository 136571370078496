import { combineReducers } from 'redux'

import contratosImobiliario from './contratoImobiliario'
import { ContratoImobiliarioState } from './contratoImobiliario/types'

export interface ImobiliarioState {
  contratosImobiliario: ContratoImobiliarioState
}

export default combineReducers({
  contratosImobiliario,
})
