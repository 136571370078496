import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import services from '@services'
import { CpfRequestType } from '@services/getCpf'
import { ApplicationState } from '@store/types'

import { setCpf, setLoadingCpf, setRedirectToUnroll } from '../actions'

export default function* getCliente({ payload }: AnyAction) {
  try {
    const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)
    const { cpf } = yield select((state: ApplicationState) => state.cpf)
    const response: CpfRequestType = yield services.getCliente(accessToken, payload.captcha)
    if (response?.data?.cpf && cpf !== response?.data?.cpf) {
      yield put(setCpf(response?.data?.cpf))
    }
    if (response?.data?.unroll) {
      yield put(setRedirectToUnroll(response?.data?.unroll))
    }
    yield put(setLoadingCpf(false))
    if (payload?.callback) {
      yield payload?.callback(response?.data?.cpf)
    }
  } catch (err) {
    yield put(setLoadingCpf(false))
    if (payload?.callback) {
      yield payload?.callback()
    }
  }
}
