import { BaseServices, HttpMethod } from '@services/base'
import { BuildContratoImobiliarioType } from '@store/imobiliario/contratoImobiliario/types'

export default (accessToken: string) => {
  const pathname = `${process.env.REACT_APP_CONTRACTS_SERVICE}/contratos`
  const method = HttpMethod.GET
  const headers = { Authorization: `Bearer ${accessToken}` }
  const host = process.env.REACT_APP_API_HOST_NAO_CORRENTISTA_CONTRACTS
  const response = BaseServices.request<ContratoImobiliarioRequestType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface ContratoImobiliarioRequestType {
  data: BuildContratoImobiliarioType[]
}
