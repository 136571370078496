import { Reducer } from 'redux'

import { ContratoPessoalState, ContratoPessoalTypes } from './types'

const INITIAL_STATE: ContratoPessoalState = {
  contratosGarantia: undefined,
  errorContratosGarantia: '',
  loadingContratosGarantia: false,
  searchGarantia: new Date(),

  contratosAntecipacao: undefined,
  errorContratosAntecipacao: '',
  loadingContratosAntecipacao: false,

  contratosResgateExpress: [],
  errorContratosResgateExpress: '',
  loadingContratosResgateExpress: false,
  searchResgateExpress: new Date(),
}

const reducer: Reducer<ContratoPessoalState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratoPessoalTypes.GET_CONTRATOS_GARANTIA:
      return {
        ...state,
        loadingContratosGarantia: true,
      }
    case ContratoPessoalTypes.SET_CONTRATOS_GARANTIA:
      return {
        ...state,
        contratosGarantia: action.payload,
        loadingContratosGarantia: false,
        errorContratosGarantia: false,
      }
    case ContratoPessoalTypes.SET_ERROR_CONTRATO_GARANTIA:
      return {
        ...state,
        errorContratosGarantia: action.payload,
        loadingContratosGarantia: false,
        contratosGarantia: [],
      }
    case ContratoPessoalTypes.SET_LOADING_GARANTIA:
      return { ...state, loadingContratosGarantia: action.payload }
    case ContratoPessoalTypes.SEARCH_GARANTIA:
      return { ...state, searchGarantia: action.payload }

    case ContratoPessoalTypes.GET_CONTRATOS_ANTECIPACAO:
      return { ...state, loadingContratosAntecipacao: true }

    case ContratoPessoalTypes.SET_CONTRATOS_ANTECIPACAO:
      return {
        ...state,
        contratosAntecipacao: action.payload,
        loadingContratosAntecipacao: false,
        errorContratosAntecipacao: false,
      }
    case ContratoPessoalTypes.SET_ERROR_CONTRATOS_ANTECIPACAO:
      return {
        ...state,
        errorContratosAntecipacao: action.payload,
        loadingContratosAntecipacao: false,
        contratosAntecipacao: [],
      }
    case ContratoPessoalTypes.SET_LOADING_ANTECIPACAO:
      return { ...state, loadingContratosAntecipacao: action.payload }

    case ContratoPessoalTypes.SET_CONTRATOS_RESGATE_EXPRESS:
      return {
        ...state,
        contratosResgateExpress: action.payload,
        loadingContratosResgateExpress: false,
        errorContratosResgateExpress: false,
      }
    case ContratoPessoalTypes.SET_ERROR_CONTRATOS_RESGATE_EXPRESS:
      return {
        ...state,
        errorContratosResgateExpress: action.payload,
        loadingContratosResgateExpress: false,
      }

    case ContratoPessoalTypes.SET_LOADING_RESGATE_EXPRESS:
      return {
        ...state,
        loadingContratosResgateExpress: true,
      }
    case ContratoPessoalTypes.SEARCH_RESGATE_EXPRESS:
      return {
        ...state,
        searchResgateExpress: action.payload,
      }
    case ContratoPessoalTypes.CLEAR:
      return {
        ...INITIAL_STATE,
        searchGarantia: state.searchGarantia,
        searchAntecipacao: state.searchGarantia,
        searchResgateExpress: state.searchResgateExpress,
      }

    default:
      return state
  }
}

export default reducer
