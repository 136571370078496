import { BaseServices, HttpMethod } from '@services/base'

export default (data: GerarGuidRequest) => {
  const captcha = data.recaptchaToken
  const pathname = process.env.REACT_APP_POST_GERAR_GUID
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST
  const headers = { 'token-captcha': captcha }

  const response = BaseServices.request({
    headers,
    pathname,
    method,
    host,
    data,
  })
  return response
}

interface ModoValidacao {
  esteiraValidacao: EsteiraValidacao
  dadosParaValidacao: DadosParaValidacao
}

interface DadosParaValidacao {
  cpf: string
  numero: string | number
  nomeDaMae?: string
  dataNascimento?: string
}

interface EsteiraValidacao {
  nomesDosModosValidacao: string[]
}

export interface GerarGuidRequest {
  modoValidacao: ModoValidacao
  recaptchaToken: string
}
