import { action, Action } from 'typesafe-actions'

import {
  PayloadGetPropostasType,
  PayloadGetTodasPropostasType,
  PropostaSelecionada,
  PropostaTypes,
  ProspotaArray,
} from './types'

export const getPropostasConsignado = (payload: PayloadGetPropostasType): Action =>
  action(PropostaTypes.GET_PROPOSTAS_CONSIGNADO_NOVO_SAGA, payload)

export const setPropostasContratoNovoConsignado = (payload: ProspotaArray[]): Action =>
  action(PropostaTypes.SET_PROPOSTAS_CONTRATO_NOVO_CONSIGNADO, payload)
export const setErrorContratoNovoConsignado = (payload: boolean): Action =>
  action(PropostaTypes.SET_ERROR_CONTRATO_NOVO_CONSIGNADO, payload)
export const setLoadingContratoNovoConsignado = (payload?: boolean): Action =>
  action(PropostaTypes.SET_LOADING_CONTRATO_NOVO_CONSIGNADO, payload)
export const clearPropostas = (): Action => action(PropostaTypes.CLEAR_PROPOSTAS)
export const setSearchNovo = (payload?: Date): Action => action(PropostaTypes.SEARCH_NOVO, payload)
export const setTodasPropostas = (payload: ProspotaArray[]): Action =>
  action(PropostaTypes.SET_TODAS_PROPOSTAS, payload)
export const getTodasPropostas = (payload: PayloadGetTodasPropostasType): Action =>
  action(PropostaTypes.GET_TODAS_PROPOSTAS, payload)
export const setErrorTodasPropostas = (payload?: boolean): Action =>
  action(PropostaTypes.SET_ERROR_TODAS_PROPOSTAS, payload)
export const setLoadingTodasPropostas = (payload?: boolean): Action =>
  action(PropostaTypes.SET_LOADING_TODAS_PROPOSTAS, payload)
export const setPropostaSelecionada = (payload?: PropostaSelecionada): Action =>
  action(PropostaTypes.SET_PROPOSTA_SELECIONADA, payload)
