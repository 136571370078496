import React, { useState } from 'react'

import { Colors, Modal, P, Paragraph } from '@interco/cp-react-ui-lib'
import appStore from '@images/appStore.png'
import logoInter from '@images/logo-inter-color.png'
import qr from '@images/qr.png'

import { Button, CodigoQr, DivImage, Image, LogoInter, Paragrath } from './styles'

const ButtonAppStore = () => {
  const [open, setOpen] = useState<boolean>(false)

  const ModalOpenQr = () => (
    <Modal style={{ padding: '90px' }} width="500px" onClose={() => setOpen(false)} visible={open}>
      <LogoInter src={logoInter} alt="Logo Inter" />
      <P
        variant="sora"
        fontWeight="700"
        fontSize="32px"
        lineHeight="40px"
        color={Colors.GRAY500}
        margin="40px 0 8px"
      >
        Baixe o app
      </P>
      <Paragrath margin="0">Venha simplificar a sua vida no Inter!</Paragrath>
      <Paragrath margin="0">
        Somos muito mais que um banco, somos uma plataforma de multisserviços.
      </Paragrath>
      <Paragraph margin="8px 0 32px">Aponte sua câmera para o QR Code e instale o app!</Paragraph>
      <DivImage>
        <CodigoQr src={qr} alt="Código QR" />
      </DivImage>
    </Modal>
  )

  return (
    <>
      <ModalOpenQr />
      <Button
        type="button"
        onClick={() => {
          setOpen(true)
        }}
      >
        <Image src={appStore} alt="Imagem app store" />
      </Button>
    </>
  )
}

export default ButtonAppStore
