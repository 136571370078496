import { put, select } from 'typed-redux-saga'
import moment from 'moment'

import services from '@services'
import { ApplicationState } from '@store/types'
import { ContratoImobiliarioRequestType } from '@services/getContratosImobiliario'
import { showError } from '@store/ui/error/actions'
import { ServiceResponseError } from '@exceptions'
import { clearStore } from '@store/actions'

import {
  setContratosImobiliario,
  setErrorImobiliario,
  setLoadingImobiliario,
  setSearchImobiliario,
} from '../actions'

const canSearch = (tabChanged: boolean, searchDate: Date) => {
  if (!tabChanged || !searchDate) return true
  if (moment(new Date()).diff(moment(new Date(searchDate)), 'seconds') > 5) return true
  return false
}

export default function* getContratosImobiliarioSaga() {
  const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)

  try {
    const { tabChanged } = yield select((state: ApplicationState) => state.ui.tabs)
    const { searchImobiliario } = yield select(
      (state: ApplicationState) => state.imobiliario.contratosImobiliario,
    )
    if (canSearch(tabChanged, searchImobiliario)) {
      const response: ContratoImobiliarioRequestType = yield services.getContratosImobiliario(
        accessToken,
      )

      yield put(setContratosImobiliario(response.data))

      yield put(setSearchImobiliario(new Date()))
    } else {
      yield put(setLoadingImobiliario(false))
    }
  } catch (error) {
    yield put(setErrorImobiliario(true))
    const ErroTyped = error as ServiceResponseError
    const { origem, isLogged } = yield select((state: ApplicationState) => state.ui.navigation)

    if (origem === 'site' && ErroTyped?.status === 401 && isLogged) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          onClose: () => clearStore(),
          actionCallback: () => clearStore(),
        }),
      )
    }
    yield put(setErrorImobiliario(true))
  } finally {
    yield put(setLoadingImobiliario(false))
  }
}
