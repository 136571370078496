import { combineReducers } from 'redux'

import propostas from './propostas'
import { PropostaState } from './propostas/types'
import contratos from './contratos'
import { ContratoState } from './contratos/types'
import login from '../login'
import { LoginState } from '../login/types'

export interface ConsignadoState {
  propostas: PropostaState
  contratos: ContratoState
  login: LoginState
}

export default combineReducers({
  propostas,
  contratos,
  login,
})
