import { BaseServices, HttpMethod } from '@services/base'

export default () => {
  const pathname = `${process.env.REACT_APP_ELEGIVEIS}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const response = BaseServices.request<CheckElegibilidadeResponse>({
    pathname,
    method,
    host,
  })
  return response
}

export interface CheckElegibilidadeResponse {
  data: { clienteElegivel: boolean }
}
