import { useCallback, useMemo, useState } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import oAuthAssistant, { oAuthScopes } from '@config/curity'
import { ApplicationState } from '@store/types'
import { setAccessToken } from '@store/login/actions'
import { setIsLogged } from '@store/ui/navigation/actions'
import { clearStore } from '@store/actions'
import routes from '@routes/routes'

const useAuth = () => {
  const dispatch = useDispatch()
  const { accessToken } = useSelector((state: ApplicationState) => state.consignado.login)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const getToken = useCallback(() => {
    oAuthAssistant?.init().then(() => {
      const token = oAuthAssistant?.getToken()
      if (token) {
        dispatch(setAccessToken(token))
        dispatch(setIsLogged(true))
      }
    })
  }, [dispatch])

  const authorize = useCallback(
    async (
      numeroProposta: string | null,
      isUnrollBrazil: string | null,
      cameFromEmail: string | null,
    ) => {
      setIsLoading(true)

      try {
        await oAuthAssistant?.init()
        await oAuthAssistant?.authorize({
          scope: oAuthScopes,
          ui_locales: 'pt-BR',
          ...(numeroProposta && { numeroProposta }),
          ...(isUnrollBrazil !== null && { 'desenrola-brasil': '' }),
          ...(cameFromEmail !== null && { cameFromEmail: 'true' }),
        })
      } finally {
        setIsLoading(false)
      }
    },
    [],
  )

  const logout = useCallback(async () => {
    setIsLoading(true)
    oAuthAssistant?.init().then(() => {
      oAuthAssistant?.logout('', false)
    })
    await dispatch(clearStore())
    setIsLoading(false)
    history.push(routes.LOGIN)
  }, [dispatch, history])

  const value = useMemo(
    () => ({
      accessToken,
      authorize,
      logout,
      getToken,
      isLoading,
    }),
    [authorize, logout, accessToken, getToken, isLoading],
  )

  return value
}

export default useAuth
