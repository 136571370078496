export enum ContratoImobiliarioTypes {
  GET_CONTRATOS_IMOBILIARIO = '@contratosImobiliario/GET_CONTRATOS_IMOBILIARIO',
  SET_CONTRATOS_IMOBILIARIO = '@contratosImobiliario/SET_CONTRATOS_IMOBILIARIO',
  SET_ERROR_IMOBILIARIO = '@contratosImobiliario/SET_ERROR_IMOBILIARIO',
  SEARCH_IMOBILIARIO = '@contratosImobiliario/SEARCH_IMOBILIARIO',
  SET_LOADING_IMOBILIARIO = '@contratosImobiliario/SET_LOADING_IMOBILIARIO',
  CLEAR = '@contratosImobiliario/CLEAR',
}

export interface ContratoImobiliarioState {
  readonly contratosImobiliario?: BuildContratoImobiliarioType[]
  readonly errorImobiliario: boolean | string
  readonly loadingContratosImobiliario: boolean
  readonly searchImobiliario: Date
}

export interface BuildContratoImobiliarioType {
  codigo: string
  numero: string
  nomeProduto: string
  statusPrognum: string
  tipoContrato: string
  ufContrato?: string
  principal: boolean
}
