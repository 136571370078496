import { BaseServices, HttpMethod } from '@services/base'

export default (accessToken: string) => {
  const pathname = `${process.env.REACT_APP_CONTRATOS_ATIVOS_VISITANTE}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const headers = { Authorization: `Bearer ${accessToken}` }
  const response = BaseServices.request<ContratosVisitanteRequestType[]>({
    pathname,
    method,
    host,
    headers,
  })
  return response
}

export interface ContratosVisitanteRequestType {
  contratos: Contrato[]
}

interface Contrato {
  numeroContrato: string
  valorContrato: number
  proximoVencimento: string
  codigoProduto: string
  modalidade: string
  dataContrato: string
  situacao: StatusContrato
}

export type StatusContrato = 'EM_DIA' | 'EM_ATRASO' | 'FECHADO'
