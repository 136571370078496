import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import moment from 'moment'

import services from '@services'
import { showError } from '@store/ui/error/actions'
import { PropostaRequestType } from '@services/getPropostasConsignado'
import { ApplicationState } from '@store/types'
import { clearStore } from '@store/actions'
import { ServiceResponseError } from '@exceptions'

import {
  setErrorContratoNovoConsignado,
  setLoadingContratoNovoConsignado,
  setPropostasContratoNovoConsignado,
  setSearchNovo,
  setTodasPropostas,
} from '../actions'

const canSearch = (tabChanged: boolean, searchDate: Date) => {
  if (!tabChanged || !searchDate) return true
  if (moment(new Date()).diff(moment(new Date(searchDate)), 'seconds') > 5) return true
  return false
}

export type ErroType = {
  response: {
    status: number
  }
}

export default function* propostasConsignado({ payload }: AnyAction) {
  const { origem, isLogged } = yield select((state: ApplicationState) => state.ui.navigation)

  try {
    const { tabChanged } = yield select((state: ApplicationState) => state.ui.tabs)
    const propostaState = yield* select((state: ApplicationState) => state.consignado.propostas)
    const searchDate = propostaState.searchNovo
    const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)

    if (canSearch(tabChanged, searchDate)) {
      yield put(setTodasPropostas([]))
      yield put(setPropostasContratoNovoConsignado([]))
      yield put(setLoadingContratoNovoConsignado(true))
      yield put(setErrorContratoNovoConsignado(false))

      const response: PropostaRequestType = yield services.getPropostasConsignado(
        payload.cpf,
        accessToken,
        origem,
      )

      const propostas = response?.data?.propostas?.length > 0 ? response?.data?.propostas : []
      yield put(setSearchNovo(new Date()))
      yield put(setPropostasContratoNovoConsignado(propostas))
      yield put(setLoadingContratoNovoConsignado(false))
      yield put(setErrorContratoNovoConsignado(false))
    }
  } catch (error) {
    const ErroTyped = error as ServiceResponseError

    if (origem === 'site' && ErroTyped?.status === 401 && isLogged) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          onClose: () => clearStore(),
          actionCallback: () => clearStore(),
        }),
      )
    }
    yield put(setLoadingContratoNovoConsignado(false))
    yield put(setErrorContratoNovoConsignado(true))
  }
}
