/**
 * Actions Types
 */

export enum PropostaTypes {
  CLEAR_PROPOSTAS = '@propostas/CLEAR_PROPOSTAS',
  SET_PROPOSTAS_CONTRATO_NOVO_CONSIGNADO = '@propostas/SET_PROPOSTAS_CONTRATO_NOVO_CONSIGNADO',
  SET_ERROR_CONTRATO_NOVO_CONSIGNADO = '@propostas/SET_ERROR_CONTRATO_NOVO_CONSIGNADO',
  SET_LOADING_CONTRATO_NOVO_CONSIGNADO = '@propostas/SET_LOADING_CONTRATO_NOVO_CONSIGNADO',
  SEARCH_NOVO = '@propostas/SEARCH_NOVO',

  SET_TODAS_PROPOSTAS = '@propostas/SET_TODAS_PROPOSTAS',
  SET_ERROR_TODAS_PROPOSTAS = '@propostas/SET_ERROR_TODAS_PROPOSTAS',
  SET_LOADING_TODAS_PROPOSTAS = '@propostas/SET_LOADING_TODAS_PROPOSTAS',
  GET_TODAS_PROPOSTAS = '@propostas/GET_TODAS_PROPOSTAS',

  GET_PROPOSTAS_CONSIGNADO_NOVO_SAGA = '@propostas/GET_PROPOSTAS_CONSIGNADO_NOVO_SAGA',

  SET_PROPOSTA_SELECIONADA = '@propostas/SET_PROPOSTA_SELECIONADA',
}

/**
 * State Type
 */

export interface PropostaState {
  readonly contratoNovoConsignado: ProspotaArray[] | []
  readonly errorContratoNovoConsignado: boolean
  readonly loadingContratoNovoConsignado: boolean
  readonly searchNovo: Date
  readonly todasPropostasConsignado: ProspotaArray[] | []
  readonly errorTodasPropostasConsignado?: boolean
  readonly loadingTodasPropostasConsignado?: boolean
  readonly propostaSelecionada?: PropostaSelecionada
}

export interface PayloadGetPropostasType {
  cpf: string
}

export interface PayloadGetTodasPropostasType {
  cpf: string
}

export interface PropostaReturnType {
  propostas: ProspotaArray[]
}

export interface ProspotaArray {
  codigoConvenio: string
  digital: boolean
  codigoProposta: string
  produto: string
  status: string
  modalidade: string
  statusAcompanhamentoPropostas: string
  tipo: string
  valorProposta: number
}

export interface PropostaSelecionada {
  tipo: string
  codigoProposta: string
  digital: boolean
  valorProposta: number
  cpf: string
}
