/**
 * Actions Types
 */

import { BuildContratoType } from '@store/consignado/contratos/types'

export enum ContratoPessoalTypes {
  GET_CONTRATOS_GARANTIA = '@contratosPessoal/GET_CONTRATOS_GARANTIA',
  SET_CONTRATOS_GARANTIA = '@contratosPessoal/SET_CONTRATOS_GARANTIA',
  SET_LOADING_GARANTIA = '@contratosPessoal/SET_LOADING_GARANTIA',
  SET_ERROR_CONTRATO_GARANTIA = '@contratosPessoal/SET_ERROR_CONTRATO_GARANTIA',
  SEARCH_GARANTIA = '@contratosPessoal/SEARCH_GARANTIA',
  CLEAR = '@contratosPessoal/CLEAR',

  GET_CONTRATOS_ANTECIPACAO = '@contratosPessoal/GET_CONTRATOS_ANTECIPACAO',
  SET_CONTRATOS_ANTECIPACAO = '@contratosPessoal/SET_CONTRATOS_ANTECIPACAO',
  SET_LOADING_ANTECIPACAO = '@contratosPessoal/SET_LOADING_ANTECIPACAO',
  SET_ERROR_CONTRATOS_ANTECIPACAO = '@contratosPessoal/SET_ERROR_CONTRATOS_ANTECIPACAO',

  GET_CONTRATOS_RESGATE_EXPRESS = '@contratosPessoal/GET_CONTRATOS_RESGATE_EXPRESS',
  SET_CONTRATOS_RESGATE_EXPRESS = '@contratosPessoal/SET_CONTRATOS_RESGATE_EXPRESS',
  SET_LOADING_RESGATE_EXPRESS = '@contratosPessoal/SET_LOADING_RESGATE_EXPRESS',
  SET_ERROR_CONTRATOS_RESGATE_EXPRESS = '@contratosPessoal/SET_ERROR_CONTRATOS_RESGATE_EXPRESS',
  SEARCH_RESGATE_EXPRESS = '@contratosPessoal/SEARCH_RESGATE_EXPRESS',
}

/**
 * State Type
 */

export interface ContratoPessoalState {
  readonly contratosGarantia?: BuildContratoType[]
  readonly errorContratosGarantia: string | boolean
  readonly loadingContratosGarantia: boolean
  readonly searchGarantia: Date

  readonly contratosAntecipacao?: BuildContratoType[]
  readonly errorContratosAntecipacao: string | boolean
  readonly loadingContratosAntecipacao: boolean

  readonly contratosResgateExpress?: BuildContratoType[]
  readonly errorContratosResgateExpress: string | boolean
  readonly loadingContratosResgateExpress: boolean
  readonly searchResgateExpress: Date
}

export interface PayloadGetContratosPessoalType {
  cpf: string
}

export interface ContratosPessoalReturnType {
  contratos: BuildContratoType[]
}

export interface ContratoPessoalArray {
  numeroContrato: string
  valorContrato: number
  proximoVencimento: string
  codigoProduto: string
}
