import { Reducer } from 'redux'

import { TabState, TabTypes } from './types'

const INITIAL_STATE: TabState = {
  selectedTab: '0',
  selectedTabDetalhamento: '0',
  tabChanged: false,
}

const reducer: Reducer<TabState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TabTypes.SET_SELECTED_TAB:
      return { ...state, selectedTab: action.payload, tabChanged: true }
    case TabTypes.SET_SELECTED_TAB_DETALHAMENTO:
      return { ...state, selectedTabDetalhamento: action.payload }
    case TabTypes.TAB_NOT_CHANGED:
      return { ...state, tabChanged: false }
    default:
      return state
  }
}

export default reducer
