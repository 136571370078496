import styled from 'styled-components'

import { Colors, FontFamily, P } from '@interco/cp-react-ui-lib'

export const Button = styled.button`
  margin: 80px 0 60px;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  max-width: 1200px;
`

export const LogoInter = styled.img`
  width: 70px;
`

export const CodigoQr = styled.img`
  width: 230px;
`

export const DivImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Paragrath = styled(P)`
  font-family: ${FontFamily.Inter};
  font-size: 14px;
  line-height: 17px;
  color: ${Colors.GRAY400};
`
