import styled from 'styled-components'

import { InterUIBottomSheet, InterUIButton } from '@interco/inter-ui-react-lib'

export const BottomSheetStyled = styled(InterUIBottomSheet)`
  margin: 0 auto;
`

export const ButtonStyled = styled(InterUIButton)`
  margin: 10px 0;
`

export const SecondaryButtonStyled = styled(InterUIButton)`
  margin: 10px 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
`

export const LoadingStyled = styled.div`
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
`
