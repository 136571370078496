import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { GerarGuidRequest } from '@services/postGerarGuid'
import { showError } from '@store/ui/error/actions'
import services from '@services'

import {
  setGerarGuidSuccess,
  setGuid,
  setGerarGuidLoading,
  setNotificationTypeEmail,
} from '../actions'
import { GerarGuidPayload } from '../types'

export type ErroType = {
  response: {
    status: number
  }
}

export default function* postGerarGuidSaga(action: AnyAction) {
  const payload = action.payload as GerarGuidPayload

  try {
    const gerarGuid: GerarGuidRequest = {
      modoValidacao: {
        esteiraValidacao: {
          nomesDosModosValidacao: payload.modoValidacao.esteiraValidacao.nomesDosModosValidacao,
        },
        dadosParaValidacao: {
          cpf: payload.modoValidacao.dadosParaValidacao.cpf,
          numero: payload.modoValidacao.dadosParaValidacao.numero,
          nomeDaMae: payload.modoValidacao.dadosParaValidacao.nomeDaMae,
          dataNascimento: payload.modoValidacao.dadosParaValidacao.dataNascimento,
        },
      },
      recaptchaToken: payload.recaptchaToken,
    }
    const { data } = yield services.postGerarGuid(gerarGuid)

    if (data.notificationType) {
      yield put(setNotificationTypeEmail(true))
    } else {
      yield put(setNotificationTypeEmail(false))
    }
    yield put(setGerarGuidSuccess(true))
    yield put(setGuid(data.guid))
    payload.callback()
  } catch (error: unknown) {
    const ErroTyped = error as ErroType
    if (ErroTyped?.response.status === 400) {
      yield put(
        showError({
          title: 'Erro ao buscar informações',
          message:
            'Não foi possível prosseguir com o login. Entre em contato com nossa central de relacionamento no número 3003-4070 para atualizar os dados cadastrais.',
          actionTitle: 'Continuar',
          onClose: yield put(setGerarGuidLoading(false)),
          actionCallback: () => setGerarGuidLoading(false),
        }),
      )
    } else {
      yield put(
        showError({
          title: 'Erro ao buscar informações',
          message: 'Aconteceu um erro ao busca algumas informações!',
          actionTitle: 'Tentar Novamente',
          onClose: yield put(setGerarGuidLoading(false)),
          actionCallback: () => setGerarGuidLoading(false),
        }),
      )
    }
  }
}
