import { Reducer } from 'redux'

import { ContratoState, ContratoTypes } from './types'

const INITIAL_STATE: ContratoState = {
  contratosConsignado: undefined,
  errorConsignado: false,
  loadingContratosConsignado: false,
  searchConsignado: new Date(),
  contratoSelecionado: undefined,
}

const reducer: Reducer<ContratoState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratoTypes.GET_CONTRATOS_CONSIGNADO:
      return {
        ...state,
        loadingContratosConsignado: true,
      }
    case ContratoTypes.SET_CONTRATOS_CONSIGNADO:
      return {
        ...state,
        contratosConsignado: action.payload,
        loadingContratosConsignado: false,
        errorConsignado: false,
      }
    case ContratoTypes.GET_CONTRATOS_CONSIGNADO_VISITANTE:
      return {
        ...state,
        loadingContratosConsignado: true,
      }
    case ContratoTypes.SET_ERROR_CONSIGNADO:
      return {
        ...state,
        errorConsignado: action.payload,
        loadingContratosConsignado: false,
        contratosConsignado: [],
      }
    case ContratoTypes.SET_LOADING_CONSIGNADO:
      return { ...state, loadingContratosConsignado: action.payload }
    case ContratoTypes.SET_CONTRATO_SELECIONADO:
      return { ...state, contratoSelecionado: action.payload }
    case ContratoTypes.SEARCH_CONSIGNADO:
      return { ...state, searchConsignado: action.payload }
    case ContratoTypes.CLEAR:
      return { ...INITIAL_STATE, searchConsignado: state.searchConsignado }

    default:
      return state
  }
}

export default reducer
