import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import MobileBridge from '@interco/inter-mobile-bridge'
import services from '@services'
import { ApplicationState } from '@store/types'
import { ContratoRequestType } from '@services/getContratosConsignado'
import {
  setContratosGarantia,
  setErrorContratoGarantia,
  setLoadingGarantia,
  setSearchGarantia,
} from '@store/pessoal/contratos/actions'
import { BuildContratoType } from '@store/consignado/contratos/types'
import { HttpStatus } from '@services/base'
import { BridgeService } from '@services/bridge'
import { isOldBridge } from '@services/base/oldBridge'

const canSearch = (tabChanged: boolean, searchDate: Date) => {
  if (!tabChanged || !searchDate) return true
  if (moment(new Date()).diff(moment(new Date(searchDate)), 'seconds') > 5) return true
  return false
}

interface ErrorType {
  status: number
  response: {
    status: number
  }
}

const verificarErro = (error: ErrorType) =>
  error?.response?.status === HttpStatus.NOT_FOUND || error?.status === HttpStatus.NOT_FOUND

export default function* getContratosGarantia({ payload }: AnyAction) {
  try {
    const { tabChanged } = yield select((state: ApplicationState) => state.ui.tabs)
    const { searchGarantia } = yield select((state: ApplicationState) => state.pessoal.contratos)
    if (canSearch(tabChanged, searchGarantia)) {
      let userInfo = { cpf: '' }

      if (isOldBridge()) {
        userInfo = yield MobileBridge.getUserInfo()
      } else if (!BridgeService.isBrowser() && isMobile) {
        userInfo = yield BridgeService.getUserInfo()
      }

      const response: ContratoRequestType = yield services.getContratosGarantia(
        payload || userInfo?.cpf,
      )
      const contratosGarantia: BuildContratoType[] = []

      yield response?.data?.contratos.forEach((contrato) => {
        const buildContrato = {
          numero: contrato?.numeroContrato,
          codigo: contrato?.numeroContrato,
          valorContrato: contrato?.valorContrato,
          proximoVencimento: contrato?.proximoVencimento,
          codigoProduto: contrato?.codigoProduto,
        }
        contratosGarantia.push(buildContrato)
      })

      yield put(setContratosGarantia(contratosGarantia))
      yield put(setSearchGarantia(new Date()))
    }
  } catch (error) {
    if (verificarErro(error as ErrorType)) {
      yield put(setContratosGarantia([]))
    } else {
      yield put(setLoadingGarantia(false))
      yield put(setErrorContratoGarantia(true))
    }
  } finally {
    yield put(setLoadingGarantia(false))
  }
}
