import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string) => {
  const pathname = `${process.env.REACT_APP_GET_TAXAS}`
  const method = HttpMethod.POST
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const data = { cpf: cpf || '' }
  const response = BaseServices.request<AntecipacaoType>({
    pathname,
    method,
    data,
    host,
  })
  return response
}

export interface AntecipacaoType {
  data: {
    vl_total_operacao: number
  }
}
