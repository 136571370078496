import { Reducer } from 'redux'

import { ContratoImobiliarioState, ContratoImobiliarioTypes } from './types'

const INITIAL_STATE: ContratoImobiliarioState = {
  contratosImobiliario: undefined,
  errorImobiliario: false,
  loadingContratosImobiliario: false,
  searchImobiliario: new Date(),
}

const reducer: Reducer<ContratoImobiliarioState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContratoImobiliarioTypes.GET_CONTRATOS_IMOBILIARIO:
      return {
        ...state,
        loadingContratosImobiliario: true,
      }
    case ContratoImobiliarioTypes.SET_CONTRATOS_IMOBILIARIO:
      return {
        ...state,
        contratosImobiliario: action.payload,
        loadingContratosImobiliario: false,
        errorImobiliario: false,
      }

    case ContratoImobiliarioTypes.SET_ERROR_IMOBILIARIO:
      return {
        ...state,
        errorImobiliario: action.payload,
        loadingContratosImobiliario: false,
        contratosImobiliario: [],
      }
    case ContratoImobiliarioTypes.SET_LOADING_IMOBILIARIO:
      return { ...state, loadingContratosImobiliario: action.payload }
    case ContratoImobiliarioTypes.SEARCH_IMOBILIARIO:
      return { ...state, searchImobiliario: action.payload }
    case ContratoImobiliarioTypes.CLEAR:
      return { ...INITIAL_STATE, searchImobiliario: state.searchImobiliario }

    default:
      return state
  }
}

export default reducer
