/**
 * Actions Types
 */

export enum AntecipacaoVeicularTypes {
  SET_SHOW_ANTECIPACAO_VEICULAR = '@antecipacaoVeicular/SET_SHOW_ANTECIPACAO_VEICULAR',
  GET_ANTECIPACAO_VEICULAR = '@antecipacaoVeicular/GET_ANTECIPACAO_VEICULAR',
  SET_LOADING_ANTECIPACAO_VEICULAR = '@antecipacaoVeicular/SET_LOADING_ANTECIPACAO_VEICULAR',
}

/**
 * State Type
 */

export interface AntecipacaoVeicularState {
  readonly showAntecipacaoVeicular: boolean
  readonly loadingAntecipacaoVeicula: boolean
}
