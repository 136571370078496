/**
 * Actions Types
 */

export enum CpfTypes {
  SET_CPF = '@cpf/SET_CPF',
  SET_REDIRECT_TO_UNROLL = '@cpf/SET_REDIRECT_TO_UNROLL',
  GET_CPF_SAGA = '@cpf/GET_CPF_SAGA',
  GET_CLIENTE_SAGA = '@cpf/GET_CLIENTE_SAGA',
  CLEAR = '@cpf/CLEAR',
  SET_DONE_CPF = '@cpf/SET_DONE_CPF',
  SET_LOADING_CPF = '@cpf/SET_LOADING_CPF',
}

/**
 * State Type
 */

export interface CpfState {
  readonly cpf: string
  readonly redirectToUnroll: boolean
  readonly isDoneCpf: boolean
  readonly loading: boolean
}

export interface PayloadGetCpf {
  callback: (cpf: string) => void
}

export interface PayloadGetCliente {
  captcha: string
  callback?: (cpf: string) => void
}
