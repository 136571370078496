import { BaseServices, HttpMethod } from '@services/base'
import { TResponseType } from '@services/base/types'
import { ContratoResponse } from '@store/antecipacaoFgts/types'

export default (cpfCnpj: string): Promise<TResponseType<ContratoResponse>> => {
  const pathname = `${process.env.REACT_APP_GET_BACK_OFFICE_CONTRATOS}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const headers = { cpfCnpj }
  const response = BaseServices.request<ContratoResponse>({
    pathname,
    method,
    headers,
    host,
  })
  return response
}
