import { Reducer } from 'redux'

import { AntecipacaoFgtsState, AntecipacaoFgtsTypes } from './types'

const INITIAL_STATE: AntecipacaoFgtsState = {
  showAntecipacaoFGTS: false,
  loadingAntecipacaoFGTS: false,
  error: false,
  loadingContratoFGTS: false,
  erroContratoFGTS: false,
  contratos: [],
}

const reducer: Reducer<AntecipacaoFgtsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AntecipacaoFgtsTypes.SET_LOADING_ANTECIPACAO_FGTS:
      return {
        ...state,
        loadingAntecipacaoFGTS: action.payload,
      }
    case AntecipacaoFgtsTypes.SET_SHOW_ANTECIPACAO_FGTS:
      return { ...state, showAntecipacaoFGTS: action.payload, error: false }

    case AntecipacaoFgtsTypes.SET_PROPOSTA_FGTS:
      return { ...state, proposta: action.payload, error: false }

    case AntecipacaoFgtsTypes.SET_ERROR:
      return { ...state, showAntecipacaoFGTS: action.payload }
    case AntecipacaoFgtsTypes.SET_LOADING_CONTRATOS_FGTS:
      return { ...state, loadingContratoFGTS: action.payload, erroContratoFGTS: false }
    case AntecipacaoFgtsTypes.SET_CONTRATOS_FGTS:
      return {
        ...state,
        contratos: action.payload,
        erroContratoFGTS: false,
        loadingContratoFGTS: false,
      }

    case AntecipacaoFgtsTypes.SET_CONTRATO_ERRO_FGTS:
      return { ...state, loadingContratoFGTS: false, erroContratoFGTS: true }
    default:
      return state
  }
}

export default reducer
