import React, { useCallback, useEffect } from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import InterWebviewBridge, { WbSessionActions } from '@interco/inter-webview-bridge'
import MobileBridge from '@interco/inter-mobile-bridge'
import { BridgeService } from '@services/bridge'
import routes from '@routes/routes'
import { isOldBridge } from '@services/base/oldBridge'
import { ApplicationState } from '@store/types'

interface AppGoBackType {
  App: {
    goBack: () => void
  }
}
export type WindowMobileType = AppGoBackType & typeof window

export const GoBackConfig = () => {
  const history = useHistory()
  const location = useLocation()
  const iWb = InterWebviewBridge.getInstance()
  const Window = window as WindowMobileType
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)

  const listenerMobileBack = useCallback(() => {
    if (location?.pathname === routes.HOME || location?.pathname === routes.ROOT) {
      BridgeService.backToNative()
    } else {
      history.goBack()
    }
  }, [history, location?.pathname])

  useEffect(() => {
    if (!isOldBridge() && !BridgeService.isBrowser()) {
      iWb.addWebviewEventListener(WbSessionActions.GO_BACK, listenerMobileBack)
      return () => {
        iWb.removeWebviewEventListener(WbSessionActions.GO_BACK, listenerMobileBack)
      }
    }
    Window.App = {
      goBack: () => {
        try {
          if (location?.pathname === routes.HOME || location?.pathname === routes.ROOT) {
            if (isOldBridge()) {
              MobileBridge.goBack()
            } else {
              history.goBack()
            }
          } else {
            history.goBack()
          }
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(e)
        }
      },
    }

    return () => null
  }, [Window, history, iWb, isApp, listenerMobileBack, location?.pathname])

  return <></>
}
