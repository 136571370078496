import { BaseServices, HttpMethod } from '@services/base'

export default (accessToken: string, captcha: string) => {
  const pathname = process.env.REACT_APP_CLIENTE
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const headers = { Authorization: `Bearer ${accessToken}`, 'token-captcha': captcha }
  const response = BaseServices.request<CpfRequestType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface CpfRequestType {
  data: {
    cpf: string
    unroll: boolean
  }
}
