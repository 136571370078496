import { combineReducers } from 'redux'

import navigation from './navigation'
import { NavigationState } from './navigation/types'
import tabs from './tabs'
import { TabState } from './tabs/types'
import error from './error'
import { ErrorState } from './error/types'

export interface UiState {
  navigation: NavigationState
  tabs: TabState
  error: ErrorState
}

export default combineReducers({
  navigation,
  tabs,
  error,
})
