import styled from 'styled-components'

import { Colors, P } from '@interco/cp-react-ui-lib'

export const Container = styled.div<{
  isWeb?: boolean
}>`
  display: flex;
  flex-direction: column;
  background: ${Colors.WHITE};
  border-radius: 8px;
  border: 1px solid ${Colors.ORANGE200};
  box-shadow: none;
  padding: 16px;

  &:hover {
    background-color: ${Colors.ORANGE100};
    border-color: ${Colors.ORANGE500};
  }
`

export const Root = styled.div<{
  isWeb?: boolean
  length: number
}>`
  cursor: pointer;
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: ${(props) => (props.isWeb ? 'auto auto auto' : '50% 50%')};
    grid-template-columns: ${(props) => props.isWeb && props.length < 2 && '50%'};
  }
  grid-gap: 8px;
`

export const TitleApp = styled(P)`
  padding-top: 38px;
  margin: 0;
`

export const DivIcon = styled.div`
  margin: 16px 2px 18px;
`

export const DescriptionWeb = styled(P)`
  margin: 0 0 20px;
`
export const CliqueAqui = styled.div`
  display: flex;
  margin: 12px 0 24px 24px;
  align-items: center;
`
