import { BaseServices, HttpMethod } from '@services/base'

export default async (data: PropostaRequest) => {
  const pathname = process.env.REACT_APP_PROPOSTA_FGTS
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST

  const headers = {
    'x-inter-cpf': data.cpf,
    'x-inter-conta': data?.conta || '',
  }

  const response = BaseServices.request({
    pathname,
    method,
    headers,
    host,
  })
  return response
}

export interface PropostaRequest {
  cpf: string
  conta?: string
}
