import { action, Action } from 'typesafe-actions'

import { AntecipacaoTypes } from './types'

export const setShowAntecipacao = (payload: boolean): Action =>
  action(AntecipacaoTypes.SET_SHOW_ANTECIPACAO, payload)
export const setLoadingAntecipacao = (payload: boolean): Action =>
  action(AntecipacaoTypes.SET_LOADING_ANTECIPACAO, payload)
export const setSearchedDate = (payload: string | Date): Action =>
  action(AntecipacaoTypes.SET_SEARCHED_DATE, payload)
export const getTaxas = (): Action => action(AntecipacaoTypes.POST_TAXAS_SAGA)
