import OAuthAssistant from '@curity/oauth-assistant'

export const isBrowser = typeof window !== 'undefined'

export const oAuthScopes = [
  'cp:consignado-visitante:read',
  'cp:consignado-visitante:restrito',
  'ci-visitante-api',
]

const debug = process.env.REACT_APP_ENVIROMENT === 'development'
const siteUrl =
  process.env.REACT_APP_ENVIROMENT === 'development'
    ? 'http://localhost:3000'
    : process.env.REACT_APP_API_HOST_EMPRESTIMO_NOVO
const baseCurityUrl = process.env.REACT_APP_HOST as string

const issuer = `${baseCurityUrl}/generic/oauth`
const clientId = 'credito-consignado-area-visitante-auth'
const openidConfigurationUrl = `${issuer}/.well-known/openid-configuration`
const baseAppUrl = siteUrl as string
const redirectUri = `${siteUrl}/emprestimo` as string

const settings: OAuthAssistant.Settings = {
  flow_type: 'code',
  base_url: baseCurityUrl,
  client_id: clientId,
  issuer,
  redirect_uri: redirectUri,
  for_origin: baseAppUrl,
  allowed_origins: [isBrowser ? window.origin : ''],
  jwt_sig_public_key: {
    format: 'issuer',
    value: null,
  },
  ignore_not_before: false,
  ignore_expiration: false,
  clock_tolerance: 0,
  debug,
  openid_configuration_url: openidConfigurationUrl,
}

const oAuthAssistant = isBrowser ? new OAuthAssistant(settings) : null

export function sso() {
  return oAuthAssistant?.authorizeHiddenFrame({
    scope: oAuthScopes,
  })
}

export default oAuthAssistant || null
