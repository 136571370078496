import { put, select } from 'typed-redux-saga'
import { AnyAction } from 'redux'
import moment from 'moment'
import { isMobile } from 'react-device-detect'

import MobileBridge from '@interco/inter-mobile-bridge'
import services from '@services'
import { ApplicationState } from '@store/types'
import { ContratoRequestType } from '@services/getContratosConsignado'
import {
  setContratosAntecipacao,
  setErrorContratosAntecipacao,
  setLoadingAntecipacao,
} from '@store/pessoal/contratos/actions'
import { BridgeService } from '@services/bridge'
import { isOldBridge } from '@services/base/oldBridge'

import {
  setContratosConsignado,
  setErrorConsignado,
  setLoadingConsignado,
  setSearchConsignado,
} from '../actions'
import { BuildContratoType } from '../types'

const canSearch = (tabChanged: boolean, searchDate: Date) => {
  if (!tabChanged || !searchDate) return true
  if (moment(new Date()).diff(moment(new Date(searchDate)), 'seconds') > 5) return true
  return false
}

export default function* getContratosConsignado({ payload }: AnyAction) {
  try {
    const { tabChanged } = yield select((state: ApplicationState) => state.ui.tabs)
    const { searchConsignado } = yield select(
      (state: ApplicationState) => state.consignado.contratos,
    )
    if (canSearch(tabChanged, searchConsignado)) {
      let userInfo = { cpf: '' }
      if (isOldBridge()) {
        userInfo = yield MobileBridge.getUserInfo()
      } else if (!BridgeService.isBrowser() && isMobile) {
        userInfo = yield BridgeService.getUserInfo()
      }
      const response: ContratoRequestType = yield services.getContratosConsignado(
        payload || userInfo?.cpf,
      )
      const contratosConsignado: BuildContratoType[] = []
      const contratosAntecipacao: BuildContratoType[] = []

      yield response?.data?.contratos.forEach((contrato) => {
        const buildContrato = {
          numero: contrato?.numeroContrato,
          codigo: contrato?.numeroContrato,
          valorContrato: contrato?.valorContrato,
          proximoVencimento: contrato?.proximoVencimento,
          codigoProduto: contrato?.codigoProduto,
        }
        if (buildContrato?.codigoProduto === process.env.REACT_APP_CODIGO_ANTECIPACAO) {
          contratosAntecipacao.push(buildContrato)
        } else {
          contratosConsignado.push(buildContrato)
        }
      })

      yield put(setContratosAntecipacao(contratosAntecipacao))
      yield put(setContratosConsignado(contratosConsignado))

      yield put(setSearchConsignado(new Date()))
    } else {
      yield put(setLoadingAntecipacao(false))
      yield put(setLoadingConsignado(false))
    }
  } catch (error) {
    yield put(setErrorContratosAntecipacao(true))
    yield put(setErrorConsignado(true))
  } finally {
    yield put(setLoadingAntecipacao(false))
    yield put(setLoadingConsignado(false))
  }
}
