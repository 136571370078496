import { Reducer } from 'redux'

import { NavigationState, NavigationTypes } from './types'

const INITIAL_STATE: NavigationState = {
  isApp: false,
  origem: 'site',
  title: 'Empréstimo',
  hideHeader: false,
  hideFooter: false,
  isLogged: false,
  pageAction: '',
}

const reducer: Reducer<NavigationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavigationTypes.SET_IS_APP:
      return { ...state, isApp: action.payload }
    case NavigationTypes.SET_ORIGEM:
      return { ...state, origem: action.payload }
    case NavigationTypes.SET_TITLE:
      return { ...state, title: action.payload }
    case NavigationTypes.SET_HIDE_HEADER:
      return { ...state, hideHeader: action.payload }
    case NavigationTypes.SET_HIDE_FOOTER:
      return { ...state, hideFooter: action.payload }
    case NavigationTypes.SET_IS_LOGGED:
      return { ...state, isLogged: action.payload }
    case NavigationTypes.SET_PAGE_ACTION:
      return { ...state, pageAction: action.payload }
    default:
      return state
  }
}

export default reducer
