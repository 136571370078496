import { BaseServices, HttpMethod } from '@services/base'

export default () => {
  const pathname = `${process.env.REACT_APP_CONTRACTS_SERVICE}/contratos`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_API_HOST_CONTRACTS
  const response = BaseServices.request<ContratoRequestType>({
    pathname,
    method,
    host,
  })
  return response
}

export interface ContratoRequestType {
  data: {
    status?: number
  }
}
