import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import getAntecipacaoFGTS from './sagas/getAntecipacaoFGTSSaga'
import getPropostaFGTSSaga from './sagas/getPropostaFGTSSaga'
import getContratoFGTSSaga from './sagas/getContratoFGTSSaga'
import { AntecipacaoFgtsTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(AntecipacaoFgtsTypes.GET_ANTECIPACAO_FGTS, getAntecipacaoFGTS)
  yield* takeLatest(AntecipacaoFgtsTypes.GET_PROPOSTA_FGTS, getPropostaFGTSSaga)
  yield* takeLatest(AntecipacaoFgtsTypes.GET_CONTRATOS_FGTS, getContratoFGTSSaga)
}
