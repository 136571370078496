import { action, Action } from 'typesafe-actions'

import { AntecipacaoVeicularTypes } from './types'

export const setShowAntecipacaoVeicular = (payload: boolean): Action =>
  action(AntecipacaoVeicularTypes.SET_SHOW_ANTECIPACAO_VEICULAR, payload)

export const setLoadingAntecipacaoVeicular = (payload: boolean): Action =>
  action(AntecipacaoVeicularTypes.SET_LOADING_ANTECIPACAO_VEICULAR, payload)

export const getAntecipacaoVeicular = (): Action =>
  action(AntecipacaoVeicularTypes.GET_ANTECIPACAO_VEICULAR)
