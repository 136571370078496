import { ContratoArray } from '@store/consignado/contratos/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string) => {
  const pathname = `${process.env.REACT_APP_CONTRATOS_ATIVOS}`
  const method = HttpMethod.GET
  const headers = { cpf }
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const response = BaseServices.request<ContratoRequestType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface ContratoRequestType {
  data: {
    contratos: ContratoArray[]
  }
}
