import axios, { AxiosError } from 'axios'
import { isBrowser } from 'react-device-detect'

import { BridgeService, TBridgeResponseError, TParamsRequestBrigde } from '@services/bridge'
import { ServiceResponseError } from '@exceptions'

import { TParamsRequest, TResponseType } from './types'
import { callByOldBridge, isOldBridge } from './oldBridge'

export * from './types'
export class BaseServices {
  static request = async <T>({
    headers = { 'Content-Type': 'application/json' },
    pathname,
    method,
    data,
    host,
    engine,
  }: TParamsRequest): Promise<TResponseType<T>> => {
    if (BridgeService.isBrowser() || isBrowser) {
      return BaseServices.callByAxios<T>({ headers, pathname, method, data, host })
    }
    if (isOldBridge()) {
      return callByOldBridge({ headers, pathname, method, data, host, engine })
    }
    return BaseServices.callByBridge<T>({ headers, pathname, method, data })
  }

  static callByAxios = async <T>({ headers, pathname, method, data, host }: TParamsRequest) => {
    let response = {} as TResponseType<T>
    try {
      response = await axios({
        headers: { ...headers },
        url: `${host}/${pathname}`,
        method,
        data,
      })
    } catch (error) {
      const axiosError = error as AxiosError
      throw new ServiceResponseError({
        status: axiosError.response?.status,
        data: axiosError.response?.data,
        message: axiosError.message,
      })
    }
    return response
  }

  static callByBridge = async <T>({ headers, pathname, method, data }: TParamsRequestBrigde) => {
    let response = {} as TResponseType<T>
    try {
      response = await BridgeService.request<T>({
        headers,
        pathname,
        method,
        data,
      })
    } catch (error) {
      let errorObj = error as TBridgeResponseError
      if (typeof error === 'string') {
        errorObj = JSON.parse(error)
      }
      if (errorObj.httpStatus && errorObj.response) {
        let errorResponse: unknown = errorObj.response

        if (typeof errorObj.response === 'string') {
          errorResponse = JSON.parse(errorObj.response)
        }
        throw new ServiceResponseError({
          message: errorObj.message,
          data: errorResponse,
          status: errorObj.httpStatus,
        })
      }
    }
    return response
  }
}
