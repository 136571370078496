import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import getContratosConsignadoSaga from './sagas/getContratosConsignadoSaga'
import getContratosConsignadoVisitanteSaga from './sagas/getContratosConsignadoVisitanteSaga'
import { ContratoTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(ContratoTypes.GET_CONTRATOS_CONSIGNADO, getContratosConsignadoSaga)
  yield* takeLatest(
    ContratoTypes.GET_CONTRATOS_CONSIGNADO_VISITANTE,
    getContratosConsignadoVisitanteSaga,
  )
}
