import React, { useCallback, useEffect, useState } from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'

import { enableDarkMode, initTheme, Theme } from '@interco/inter-ui'
import { GlobalUIInterStyle, InterTheme, InterUIFonts } from '@interco/inter-ui-react-lib'
import { ErrorMessage } from '@organisms'
import Routes from '@routes'
import { store, persistor } from '@store'
import { TrackData } from '@interco/track'
import '@interco/inter-ui/inter-ui.css'
import './App.scss'
import { interWbSession } from '@interco/inter-webview-bridge'
import { BridgeService } from '@services/bridge'

const App = () => {
  const [themeLib, setThemeLib] = useState('LIGHT')

  InterUIFonts()
  TrackData.initOrUpdate({
    runtimeEnv: process.env.REACT_APP_ENVIROMENT as EnvType,
    projectName: 'cp-emprestimo-ui',
  })
  const getAppInfo = useCallback(async () => {
    const response = await interWbSession.getAppInfo()
    if (JSON.parse(JSON.stringify(response)).isDarkMode === true) {
      enableDarkMode()
      setThemeLib('DARK')
    }
  }, [])
  useEffect(() => {
    if (!BridgeService.isBrowser()) getAppInfo()
    initTheme(Theme.PF)
  }, [getAppInfo])
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <StyledThemeProvider theme={InterTheme(themeLib)}>
          <GlobalUIInterStyle />
          <ErrorMessage />
          <Routes />
        </StyledThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
