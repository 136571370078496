import { Reducer } from 'redux'

import { PropostaState, PropostaTypes } from './types'

const INITIAL_STATE: PropostaState = {
  contratoNovoConsignado: [],
  errorContratoNovoConsignado: false,
  loadingContratoNovoConsignado: false,
  searchNovo: new Date(),
  todasPropostasConsignado: [],
  errorTodasPropostasConsignado: false,
  loadingTodasPropostasConsignado: false,
  propostaSelecionada: undefined,
}

const reducer: Reducer<PropostaState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PropostaTypes.CLEAR_PROPOSTAS:
      return {
        ...INITIAL_STATE,
        searchNovo: state?.searchNovo,
      }
    case PropostaTypes.SET_PROPOSTAS_CONTRATO_NOVO_CONSIGNADO:
      return { ...state, contratoNovoConsignado: action.payload }
    case PropostaTypes.SET_ERROR_CONTRATO_NOVO_CONSIGNADO:
      return { ...state, errorContratoNovoConsignado: action.payload }
    case PropostaTypes.SET_LOADING_CONTRATO_NOVO_CONSIGNADO:
      return { ...state, loadingContratoNovoConsignado: action.payload }
    case PropostaTypes.SEARCH_NOVO:
      return { ...state, searchNovo: action.payload }
    case PropostaTypes.SET_TODAS_PROPOSTAS:
      return {
        ...state,
        todasPropostasConsignado: action.payload,
        loadingTodasPropostasConsignado: false,
        errorTodasPropostasConsignado: false,
      }
    case PropostaTypes.SET_ERROR_TODAS_PROPOSTAS:
      return {
        ...state,
        errorTodasPropostasConsignado: action.payload,
        loadingTodasPropostasConsignado: false,
      }
    case PropostaTypes.SET_LOADING_TODAS_PROPOSTAS:
      return { ...state, loadingTodasPropostasConsignado: action.payload }
    case PropostaTypes.SET_PROPOSTA_SELECIONADA:
      return { ...state, propostaSelecionada: action.payload }
    default:
      return state
  }
}

export default reducer
