import { action, Action } from 'typesafe-actions'

import { CpfTypes, PayloadGetCliente, PayloadGetCpf } from './types'

export const setCpf = (payload: string): Action => action(CpfTypes.SET_CPF, payload)
export const setRedirectToUnroll = (payload: boolean): Action =>
  action(CpfTypes.SET_REDIRECT_TO_UNROLL, payload)
export const setIsDoneCpf = (payload: boolean): Action => action(CpfTypes.SET_DONE_CPF, payload)
export const setLoadingCpf = (payload: boolean): Action => action(CpfTypes.SET_LOADING_CPF, payload)
export const getCpfSaga = (payload: PayloadGetCpf): Action => action(CpfTypes.GET_CPF_SAGA, payload)
export const getClienteSaga = (payload: PayloadGetCliente): Action =>
  action(CpfTypes.GET_CLIENTE_SAGA, payload)
