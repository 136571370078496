import { put } from 'typed-redux-saga'
import { AnyAction } from 'redux'

import { showError } from '@store/ui/error/actions'
import services from '@services'

import { solicitaTokenSuccess, solicitaTokenRequest } from '../actions'

export default function* getSolicitaTokenSaga({ payload }: AnyAction) {
  try {
    yield services.getSolicitaToken(payload)

    yield put(solicitaTokenSuccess(true))
  } catch (error) {
    yield put(
      showError({
        title: 'Erro ao solicitar token.',
        message: 'Aconteceu um erro ao solicitar token!',
        actionTitle: 'Tentar Novamente',
        actionCallback: () => solicitaTokenRequest(payload),
      }),
    )
  }
}
