/**
 * Actions Types
 */

export enum LoginTypes {
  SET_LOGIN_ERROR = '@login/SET_LOGIN_ERROR',
  SET_LOGIN_SUCCESS = '@login/SET_LOGIN_SUCCESS',
  POST_LOGIN_SAGA = '@login/POST_LOGIN_SAGA',

  SET_GERAR_GUID_SUCCESS = '@login/SET_GERAR_GUID_SUCCESS',
  SET_GUID = '@login/SET_GUID',
  SET_ACCESS_TOKEN = '@login/SET_ACCESS_TOKEN',
  SET_NOTIFICATION_TYPE_EMAIL = '@login/SET_NOTIFICATION_TYPE_EMAIL',
  GERAR_GUID_REQUEST = '@login/GERAR_GUID_REQUEST',
  SET_GERAR_GUID_LOADING = '@login/GERAR_GUID_LOADING',

  SOLICITA_TOKEN_REQUEST = '@login/SOLICITA_TOKEN_REQUEST',
  SOLICITA_TOKEN_SUCCESS = '@login/SOLICITA_TOKEN_SUCCESS',

  EFETUAR_LOGIN_REQUEST = '@login/EFETUAR_LOGIN_REQUEST',
  EFETUAR_LOGIN_SUCCESS = '@login/EFETUAR_LOGIN_SUCCESS',
  SET_EFETUAR_LOGIN_LOADING = '@login/SET_EFETUAR_LOGIN_LOADING',

  CLEAR_LOGIN = '@logoff/CLEAR_LOGIN',
  SESSAO_EXPIRADA = '@logoff/SESSAO_EXPIRADA',
}

/**
 * State Type
 */

export interface LoginState {
  readonly gerarGuidSuccess: boolean
  readonly guid: string
  readonly accessToken: string | null
  readonly notificationTypeEmail: boolean
  readonly solicitaTokenSuccess: boolean
  readonly loading: boolean
  readonly gerarGuidLoading: boolean
  readonly sessaoExpirada: boolean
}

interface ModoValidacao {
  esteiraValidacao: EsteiraValidacao
  dadosParaValidacao: DadosParaValidacao
}

interface DadosParaValidacao {
  cpf: string
  numero: string | number
  nomeDaMae?: string
  dataNascimento?: string
}

interface EsteiraValidacao {
  nomesDosModosValidacao: string[]
}

export interface GerarGuidPayload {
  modoValidacao: ModoValidacao
  recaptchaToken: string
  callback: () => void
}

export interface SolicitaTokenPayload {
  guid: string
  tipo: 'SMS_NOTIFICATION' | 'EMAIL_NOTIFICATION' | undefined
  recaptchaToken: string
}

export interface EfetuarLoginPayload {
  token: string
  guid: string
  recaptchaToken: string
  onSuccess: () => void
}
