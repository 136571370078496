import { takeLatest, SagaGenerator } from 'typed-redux-saga'

import postGerarGuidSaga from './sagas/postGerarGuidSaga'
import postEfetuarLoginSaga from './sagas/postEfetuarLoginSaga'
import { getSolicitaTokenSaga } from './sagas/index'
import { LoginTypes } from './types'

export default function* watch(): SagaGenerator<void> {
  yield* takeLatest(LoginTypes.GERAR_GUID_REQUEST, postGerarGuidSaga)
  yield* takeLatest(LoginTypes.SOLICITA_TOKEN_REQUEST, getSolicitaTokenSaga)
  yield* takeLatest(LoginTypes.EFETUAR_LOGIN_REQUEST, postEfetuarLoginSaga)
}
