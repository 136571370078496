/**
 * Actions Types
 */

export enum AntecipacaoTypes {
  SET_SHOW_ANTECIPACAO = '@antecipacao/SET_SHOW_ANTECIPACAO',
  POST_TAXAS_SAGA = '@antecipacao/POST_TAXAS_SAGA',
  SET_LOADING_ANTECIPACAO = '@antecipacao/SET_LOADING_ANTECIPACAO',
  SET_SEARCHED_DATE = '@antecipacao/SET_SEARCHED_DATE',
}

/**
 * State Type
 */

export interface AntecipacaoState {
  readonly showAntecipacao: boolean
  readonly loadingAntecipacao: boolean
  readonly searchedDate: string | Date | null
}
