import React from 'react'

import { useHistory } from 'react-router-dom'

import { Colors, FontFamily, Grid, P } from '@interco/cp-react-ui-lib'
import ChevronRight from '@interco/icons/orangeds/XL/chevron-right'

import { CliqueAqui, Container, DescriptionWeb, DivIcon, Root, TitleApp } from './styles'

interface Product {
  title: string
  description?: string
  icon: JSX.Element
  path: string
  fullpath: boolean
}

interface Props {
  products: Product[]
  isApp: boolean
}

const ProductBox = ({ products, isApp }: Props) => {
  const history = useHistory()

  if (isApp) {
    return (
      <Root length={products.length}>
        {products.map((product) => (
          <Container
            key={product.path}
            onClick={() => {
              if (product.path && !product.fullpath) history.push(product.path)
              if (product.path && product.fullpath) window.location.href = product.path
            }}
          >
            <Grid>{product.icon}</Grid>
            <TitleApp
              fontFamily={FontFamily.Inter}
              fontWeight={700}
              color={Colors.GRAY500}
              fontSize="14px"
              lineHeight="15px"
            >
              {product.title}
            </TitleApp>
          </Container>
        ))}
      </Root>
    )
  }
  return (
    <Root isWeb length={products.length}>
      {products.map((product) => (
        <Container
          isWeb
          key={product.title}
          onClick={() => {
            if (product.path && !product.fullpath) history.push(product.path)
            if (product.path && product.fullpath) window.location.href = product.path
          }}
        >
          <DivIcon>{product.icon}</DivIcon>
          <P
            fontFamily={FontFamily.Sora}
            fontWeight={600}
            color={Colors.GRAY500}
            margin="24px 0 8px 0"
            fontSize="16px"
            lineHeight="20px"
          >
            {product.title}
          </P>
          <DescriptionWeb>{product.description}</DescriptionWeb>
          <CliqueAqui>
            <P margin="0 12px 0 0" fontWeight={600} color={Colors.ORANGE500}>
              Clique aqui
            </P>
            <ChevronRight width={32} height={32} />
          </CliqueAqui>
        </Container>
      ))}
    </Root>
  )
}

export default ProductBox
