import { put, select } from 'typed-redux-saga'
import moment from 'moment'
import { AxiosResponse } from 'axios'

import { ServiceResponseError } from '@exceptions'
import services from '@services'
import { ApplicationState } from '@store/types'
import { showError } from '@store/ui/error/actions'
import { ContratosVisitanteRequestType } from '@services/getContratosConsignadoVisitante'
import { clearStore } from '@store/actions'

import { setContratosConsignado, setErrorConsignado } from '../actions'
import { BuildContratoType } from '../types'

export default function* getContratosConsignadoVisitante() {
  try {
    const { accessToken } = yield select((state: ApplicationState) => state.consignado.login)

    const response: AxiosResponse<ContratosVisitanteRequestType> =
      yield services.getContratosConsignadoVisitante(accessToken)
    const contratosConsignado: BuildContratoType[] = []
    yield response?.data?.contratos.forEach((contrato) => {
      const buildContrato = {
        numero: contrato?.numeroContrato,
        codigo: contrato?.numeroContrato,
        valorContrato: contrato?.valorContrato,
        proximoVencimento: contrato?.proximoVencimento,
        codigoProduto: contrato?.codigoProduto,
        status: contrato?.situacao,
        dataContrato: moment(contrato?.dataContrato, 'YYYY-MM-DD').toDate(),
        modalidade: contrato?.modalidade,
      }

      contratosConsignado.push(buildContrato)
    })

    yield put(setContratosConsignado(contratosConsignado))
  } catch (error) {
    const ErroTyped = error as ServiceResponseError
    const { origem, isLogged } = yield select((state: ApplicationState) => state.ui.navigation)

    if (origem === 'site' && ErroTyped?.status === 401 && isLogged) {
      yield put(
        showError({
          title: 'Sessão expirada',
          message: 'Efetue o login novamente.',
          actionTitle: 'Continuar',
          onClose: () => clearStore(),
          actionCallback: () => clearStore(),
        }),
      )
    }
    yield put(setErrorConsignado(true))
  }
}
