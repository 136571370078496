import React from 'react'

import { useSelector } from 'react-redux'

import { Page } from '@interco/cp-react-ui-lib'
import { InnerLoading } from '@molecules'
import { ApplicationState } from '@store/types'

const FallbackPage = () => {
  const { isApp } = useSelector((state: ApplicationState) => state.ui.navigation)
  return (
    <Page id="novo-fallback" isApp={isApp} hideHeader={isApp} hasProgressStep={false}>
      <InnerLoading height="150px" size="xl" thickness={3} />
    </Page>
  )
}

export default FallbackPage
