import { ProspotaArray } from '@store/consignado/propostas/types'
import { BaseServices, HttpMethod } from '@services/base'

export default (cpf: string, accessToken: string, origem: string) => {
  const pathname = `${process.env.REACT_APP_PROPOSTAS_VISITANTE}`
  const method = HttpMethod.GET
  const host = process.env.REACT_APP_HOST_CONSIGNADO
  const headers =
    origem === 'site'
      ? { Authorization: `Bearer ${accessToken}`, 'X-Inter-User-Social-Id': cpf }
      : undefined

  const response = BaseServices.request<PropostaVisitanteRequestType>({
    headers,
    pathname,
    method,
    host,
  })
  return response
}

export interface PropostaVisitanteRequestType {
  data: {
    propostas: ProspotaArray[]
  }
}
