import { BuildContratoType } from '@store/consignado/contratos/types'

/**
 * Actions Types
 */

export enum AntecipacaoFgtsTypes {
  SET_SHOW_ANTECIPACAO_FGTS = '@antecipacaoFgts/SET_SHOW_ANTECIPACAO_FGTS',
  GET_ANTECIPACAO_FGTS = '@antecipacaoFgts/GET_ANTECIPACAO_FGTS',
  SET_LOADING_ANTECIPACAO_FGTS = '@antecipacaoFgts/SET_LOADING_ANTECIPACAO_FGTS',
  GET_PROPOSTA_FGTS = '@antecipacaoFgts/GET_PROPOSTA_FGTS',
  SET_PROPOSTA_FGTS = '@antecipacaoFgts/SET_PROPOSTA_FGTS',
  SET_ERROR = '@antecipacaoFgts/SET_ERROR',
  SET_LOADING_CONTRATOS_FGTS = '@antecipacaoFgts/SET_LOADING_CONTRATOS_FGTS',
  GET_CONTRATOS_FGTS = '@antecipacaoFgts/GET_CONTRATOS_FGTS',
  SET_CONTRATOS_FGTS = '@antecipacaoFgts/SET_CONTRATOS_FGTS',
  SET_CONTRATO_ERRO_FGTS = '@antecipacaoFgts/SET_CONTRATO_ERRO_FGTS',
}

/**
 * State Type
 */

export interface AntecipacaoFgtsState {
  readonly showAntecipacaoFGTS: boolean
  readonly loadingAntecipacaoFGTS: boolean
  readonly error: boolean
  readonly proposta?: PropostaData
  loadingContratoFGTS: boolean
  erroContratoFGTS: boolean
  contratos: BuildContratoType[]
}

export interface PropostaResponse {
  data: PropostaData
  status: number
}

export interface PropostaData {
  uuidSimulacao: string
  valorLiberado: number
  dataProposta: Date
  stepPropostaSworks: string
  readonly contratos: BuildContratoType[]
  readonly loadingContratoFGTS: boolean
  readonly erroContratoFGTS: boolean
}

export interface ContratoResponse {
  status: number
  data: {
    contratos: Contrato[]
  }
}

export interface Contrato {
  uuidContrato: string
  numeroContrato?: number
  valorContrato: number
  dataProximoVencimento?: string
}
